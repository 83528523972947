import { Spinner } from '@/components/Spinner';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  ButtonIcon,
  Input,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';
import { TFrame, TFrameWithID } from '../types';
import styles from './../Frame.module.scss';

type TEditButton = {
  id: number;
};

type TUpdate = {
  id?: number;
  designation?: string;
};

type TPartDetailItem = {
  value: TFrame['designation'];
  frame_family: string;
  frame: string;
  editable: boolean;
};

type TPredecessorList = {
  id: number;
  originalStr: string;
  str: string;
  setDynamicValue: React.Dispatch<React.SetStateAction<TFrameWithID>>;
  setCurrentEdits: React.Dispatch<React.SetStateAction<number[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdate: (...args: any[]) => void;
};

const List = (props: TPredecessorList) => {
  const [originalValue, setOriginalValue] = useState(props.originalStr);
  const [publish, setPublish] = useState(false);

  // Keep original value to revert in case of cancel
  useEffect(() => {
    setOriginalValue(props.originalStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (publish) return <Spinner borderSize={2} size={16} color="#CCCCCC" />;

  const Cancel = () => {
    //New row
    if (props.id === 0) {
      /* props.setDynamicValue((prev) =>
        prev.filter((item) => item.id !== props.id),
      ); */
    } else {
      //updated row
      props.setDynamicValue((prevState) => {
        return { ...prevState, value: originalValue };
      });
    }

    props.setCurrentEdits((prevNumbers) =>
      prevNumbers.filter((number) => number !== props.id),
    );
  };

  const Confirm = () => {
    setPublish(true);
    props.onUpdate();
  };
  return (
    <div
      key={'LIST' + props.id}
      style={{
        display: 'grid',
        gridTemplateColumns: '80% 100px',
        gap: 10,
        paddingBottom: 5,
        paddingTop: 5,
      }}
    >
      <div>
        <Input
          onKeyUp={(event) => event.key === 'Enter' && Confirm()}
          id={'Designation' + props.id}
          size="small"
          defaultValue={props.str}
          onChange={(event) => {
            props.setDynamicValue((prevState) => {
              return { ...prevState, value: event.target.value };
            });
          }}
        />
      </div>
      <div>
        <Stack direction="row">
          <ButtonIcon
            hoverType="color"
            icon="IconClose"
            size="extra-small"
            tooltipMessage="Cancel"
            onClick={Cancel}
          />

          <ButtonIcon
            hoverType="color"
            icon="IconCheck"
            size="extra-small"
            tooltipMessage="Confirm"
            onClick={Confirm}
          />
        </Stack>
      </div>
    </div>
  );
};

const FrameDetailDesignation = ({
  value,
  frame_family,
  frame,
  editable = true,
}: TPartDetailItem) => {
  const [currentEdits, setCurrentEdits] = useState<number[]>([]);
  const [dynamicValue, setDynamicValue] = useState(value);

  const { makeApiCall } = useApiCall<{
    [key: string]: TFrameWithID[];
  }>();

  useEffect(() => {
    setDynamicValue(value);
  }, [value]);

  if (!dynamicValue) return;

  const Update = (props: TUpdate) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'PUT',
          useAsFetch: true,
          url:
            import.meta.env.VITE_APP_API_URL +
            `/frame/${frame_family}/${frame}/designation`,
          payload: {
            id: props.id,
            designation: props.designation,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== props.id),
        );

        if (result.status && result.status !== 200) {
          alert("ERROR: Maintenance Concept couldn't be updated");

          return;
        }
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const EditButton = (props: TEditButton) => {
    return (
      <div className={styles.edit}>
        <div style={{ display: 'inline-block', marginLeft: 20 }}>
          <Stack direction="row" gap={10}>
            <Button
              type="link"
              allCaps={false}
              onClick={() =>
                setCurrentEdits((prevNumbers) => [...prevNumbers, props.id])
              }
            >
              Edit Designation
            </Button>
          </Stack>
        </div>
      </div>
    );
  };

  if (!value) return;

  return (
    <>
      <Typography tag="textdefault">
        {currentEdits.includes(dynamicValue.id) ? (
          <List
            key={'Designation_' + dynamicValue.id}
            id={dynamicValue.id}
            str={dynamicValue.value}
            originalStr={dynamicValue.value}
            setDynamicValue={setDynamicValue}
            setCurrentEdits={setCurrentEdits}
            onUpdate={() =>
              Update({
                id: dynamicValue.id,
                designation: dynamicValue.value,
              })
            }
          />
        ) : (
          <div className={styles.editableContent}>
            <span>
              {dynamicValue.value ? dynamicValue.value : 'No designation added'}
            </span>

            {editable && <EditButton id={dynamicValue.id} />}
          </div>
        )}
      </Typography>
    </>
  );
};

export default FrameDetailDesignation;
