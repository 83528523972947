import { CONSTANTS } from '@/constants/constants';
import { AreaContext } from '@/contexts/AreaContext';
import { Typography } from '@data-products-and-ai/react-components';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Frames.module.scss';
const Frames = () => {
  const { area, setArea } = useContext(AreaContext);
  document.title = CONSTANTS.en.TITLE + ' - Frames';

  useEffect(() => {
    if (area !== 'frames') setArea('frames');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="contentContainer">
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr 1fr',
          gap: 20,
        }}
      >
        <div>
          <div className={styles.frameCategory}>
            <Typography tag="h3">Annular Combustor</Typography>
          </div>
          <div className={styles.frameSubCategory}>50Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/SGT5-3000E">SGT5-3000E (V94.2A)</Link>
          </div>
          <div className={styles.frame}>
            <Link to="/frames/SGT5-4000F">SGT5-4000F (V94.3A)</Link>
          </div>
          <div className={styles.frameSubCategory}>60Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/SGT6-4000F">SGT6-4000F (V84.3A)</Link>
          </div>
          <div className={styles.frameSubCategory}>50Hz/60Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/SGT-1000F">SGT-1000F (V64.3A)</Link>
          </div>
        </div>

        <div>
          <div className={styles.frameCategory}>
            <Typography tag="h3">Can-Annular Combustor</Typography>
          </div>

          <div className={styles.frameSubCategory}>50Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/SGT5-8000HL">SGT5-8000HL (PHX)</Link>
          </div>
          <div className={styles.frame}>
            <Link to="/frames/SGT5-9000HL">SGT5-9000HL (PHX)</Link>
          </div>
          <div className={styles.frameSubCategory}>60Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/SGT6-9000HL">SGT6-9000HL (PHX)</Link>
          </div>
        </div>

        <div>
          <div className={styles.frameCategory}>
            <Typography tag="h3">Can Combustor</Typography>
          </div>

          <div className={styles.frameSubCategory}>50Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/SGT5-8000H">SGT5-8000H (NGF50)</Link>
          </div>

          <div className={styles.frameSubCategory}>60Hz</div>
          {/*    <div className={styles.frame}>
            <Link to="/frames/SGT6-3000E">SGT6-3000E (W501D5A)</Link>
          </div>
          <div className={styles.frame}>
            <Link to="/frames/SGT6-5000F">SGT6-5000F (W501F)</Link>
          </div>
          <div className={styles.frame}>
            <Link to="/frames/SGT6-6000G">SGT6-6000G (W501G)</Link>
          </div> */}
          <div className={styles.frame}>  
            <Link to="/frames/SGT6-8000H">SGT6-8000H (NGF60)</Link>
          </div>
        </div>

        <div>
          <div className={styles.frameCategory}>
            <Typography tag="h3">Silo Combustor</Typography>
          </div>

          <div className={styles.frameSubCategory}>50Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/SGT5-2000E">SGT5-2000E (V94.2)</Link>
          </div>
          <div className={styles.frame}>
            <Link to="/frames/V94.3">V94.3</Link>
          </div>

          <div className={styles.frameSubCategory}>60Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/SGT6-2000E">SGT6-2000E (V84.2)</Link>
          </div>
          <div className={styles.frame}>
            <Link to="/frames/V84.3">V84.3</Link>
          </div>

          <div className={styles.frameSubCategory}>50Hz / 60Hz</div>
          <div className={styles.frame}>
            <Link to="/frames/V64.3">V64.3</Link>
          </div>
        </div>
      </div>
      {/* </Stack> */}
    </div>
  );
};

export default Frames;
