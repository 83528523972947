import { BOM } from '@/components/BOM';
import { Spinner } from '@/components/Spinner';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  ButtonIcon,
  Divider,
  Stack,
} from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';
import { TPart, TPartWithId } from '../types';
import styles from './PartDetail.module.scss';
import { LinkToPage } from './PartDetailTables';

type TPartDetailItem = {
  value: TPart['predecessors'];
  bom: string;
  editable: boolean;
};

type TUpdate = {
  id?: number;
  predecessor?: string;
};

const PartDetailPredecessors = ({ value, bom, editable }: TPartDetailItem) => {
  const [currentEdits, setCurrentEdits] = useState<number[]>([]);
  const [dynamicValue, setDynamicValue] = useState(value);

  const { makeApiCall } = useApiCall<{
    [key: string]: TPartWithId[];
  }>();

  type TEditButton = {
    id: number;
  };

  const EditButton = (props: TEditButton) => {
    return (
      <div className={styles.edit}>
        <div style={{ display: 'inline-block', marginLeft: 20 }}>
          <Stack direction="row" gap={10}>
            <Button
              type="link"
              allCaps={false}
              onClick={() =>
                setCurrentEdits((prevNumbers) => [...prevNumbers, props.id])
              }
            >
              Edit
            </Button>

            <Divider orientation="vertical" />
            <Button
              type="link"
              allCaps={false}
              onClick={() => Delete({ id: props.id })}
            >
              Delete
            </Button>
          </Stack>
        </div>
      </div>
    );
  };

  type TPredecessorList = {
    id: number;
    originalStr: string;
    str: string;
    setDynamicValue: React.Dispatch<React.SetStateAction<TPartWithId[]>>;
    setCurrentEdits: React.Dispatch<React.SetStateAction<number[]>>;
  };

  const handleBOM = (
    value: TPart,
    id: number,
    setPublish: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setPublish(true);
    if (id === 0) {
      Insert({ id: id, predecessor: value.title.bom });
    } else {
      Update({ id: id, predecessor: value.title.bom });
    }

    /* navigate('/parts/part_development/' + value.title.bom); */
  };

  const List = (props: TPredecessorList) => {
    const [originalValue, setOriginalValue] = useState(props.originalStr);
    const [publish, setPublish] = useState(false);

    // Keep original value to revert in case of cancel
    useEffect(() => {
      setOriginalValue(props.originalStr);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const Cancel = () => {
      //New row
      if (props.id === 0) {
        props.setDynamicValue((prev) =>
          prev.filter((item) => item.id !== props.id),
        );
      } else {
        //updated row
        props.setDynamicValue((prevState) => {
          return prevState.map((dynItem) => {
            if (dynItem.id === props.id) {
              return { ...dynItem, value: originalValue };
            }
            return dynItem;
          });
        });
      }

      props.setCurrentEdits((prevNumbers) =>
        prevNumbers.filter((number) => number !== props.id),
      );
    };

    if (publish) return <Spinner borderSize={2} size={16} color="#CCCCCC" />;

    return (
      <>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '400px 100px',
            gap: 10,
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          <div>
            <BOM
              placeholder="Select a predecessor"
              cb={(value) => handleBOM(value, props.id, setPublish)}
            />
          </div>
          <div>
            <ButtonIcon
              hoverType="color"
              icon="IconClose"
              size="extra-small"
              tooltipMessage="Cancel"
              onClick={Cancel}
            />
          </div>
        </div>
      </>
    );
  };

  const Update = (props: TUpdate) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'PUT',
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/part/${bom}/predecessors`,
          payload: { id: props.id, predecessor: props.predecessor },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        if (result.status && result.status !== 200) {
          alert("ERROR: Predecessor couldn't be updated");
          return;
        }

        setDynamicValue((prevState) =>
          prevState.map((item) =>
            item.id === props.id
              ? {
                  ...item,
                  value: props.predecessor as string,
                }
              : item,
          ),
        );
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== props.id),
        );
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const Insert = (props: Partial<TUpdate>) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'POST',
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/part/${bom}/predecessors`,
          payload: {
            predecessor: props.predecessor,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== 0),
        );

        if (result.status && result.status !== 200) {
          alert("ERROR: Predecessor couldn't be inserted");

          return;
        }

        setDynamicValue((prevState) =>
          prevState.map((item) =>
            item.id === 0
              ? {
                  ...item,
                  id: result.ID,
                  value: props.predecessor as string,
                }
              : item,
          ),
        );
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const Delete = (props: Partial<TUpdate>) => {
    if (confirm('Are you sure you want to delete this predecessor?') === true) {
      const ApiCall = async () => {
        try {
          return await makeApiCall({
            method: 'DELETE',
            useAsFetch: true,
            url: import.meta.env.VITE_APP_API_URL + `/part/${bom}/predecessors`,
            payload: { record_id: props.id },
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          console.error('Error occurred:', error);

          throw error;
        }
      };

      ApiCall()
        .then((result) => {
          if (result.status && result.status !== 200) {
            alert("ERROR: Predecessor couldn't be deleted");
            return;
          }

          setDynamicValue((prevState) =>
            prevState.filter((item) => item.id !== props.id),
          );
        })
        .catch(() => {
          alert('Something went wrong');
        });
    }
  };

  const addNewRow = () => {
    setCurrentEdits((prevNumbers) => [...prevNumbers, 0]);
    setDynamicValue((prev) => [...prev, { id: 0, value: '' }]);
  };
  if (!value) return;
  return (
    <ul className={styles['ul-none']}>
      {dynamicValue.map((item: TPartWithId) => (
        <li key={item.id} className={styles['li-none']}>
          {currentEdits.includes(item.id) ? (
            <List
              key={'Predecessor_' + item.id}
              id={item.id}
              str={item.value}
              originalStr={item.value}
              setDynamicValue={setDynamicValue}
              setCurrentEdits={setCurrentEdits}
            />
          ) : (
            <div className={styles.editableContent}>
              <LinkToPage id={item.value} baseUrl="/parts/detail/" />
              {editable && <EditButton id={item.id} />}
            </div>
          )}
        </li>
      ))}
      {editable && dynamicValue.length < 1 && (
        <li key="PredecessorAdd" className={styles['li-none']}>
          {!currentEdits.includes(0) && (
            <Button
              type="primaryOutline"
              size="small"
              onClick={() => addNewRow()}
            >
              Add predecessor
            </Button>
          )}
        </li>
      )}
    </ul>
  );
};

export default PartDetailPredecessors;
