import { Link, useParams, useSearchParams } from 'react-router-dom';

import { BOM } from '@/components/BOM';
import { AreaContext } from '@/contexts/AreaContext';
import useApiCall from '@/hooks/useApiCall';
import useGroups from '@/hooks/useGroups';
import {
  ButtonIcon,
  Divider,
  Icon,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { ReactNode, useContext, useEffect, useState } from 'react';
import PartDetailAlternativeSources from '../Part/components/PartDetailAlternativeSources';
import PartDetailApplications from '../Part/components/PartDetailApplications';
import PartDetailCastingVendors from '../Part/components/PartDetailCastingVendors';
import PartDetailDescription from '../Part/components/PartDetailDescription';
import PartDetailDesignFeatures from '../Part/components/PartDetailDesignFeatures';
import PartDetailEarmarks from '../Part/components/PartDetailEarmarks';
import PartDetailOwner from '../Part/components/PartDetailOwner';
import PartDetailPredecessors from '../Part/components/PartDetailPredecessors';
import PartDetailReferences from '../Part/components/PartDetailReferences';
import PartDetailSuccessors from '../Part/components/PartDetailSuccessors';
import { TTableSize } from '../Part/components/PartDetailTables';
import PartApplicationMatrixTable from '../Part/components/tables/PartApplicationMatrix';
import PartMaterialTable from '../Part/components/tables/PartMaterialTable';
import PartScrapRatesTable from '../Part/components/tables/PartScrapRates';
import PartSetCountTable from '../Part/components/tables/PartSetCountTable';
import PartStatusTable from '../Part/components/tables/PartStatusTable';
import { TPart } from '../Part/types';

type MatrixProps = {
  matrix: ReactNode[][];
};

const initialMatrix: ReactNode[][] = [[]];

/* 'Part Lifes (new unit)', */
const headers = [
  'Part',
  'Description',
  'Earmarks',
  'Owner(s)',
  'Status',
  'Set / Part Count',
  'Material',
  'Casting Vendor(s)',
  'Design Features',
  'Applications',
  'Scrap Rates (service)',
  'Application Matrix (service)',
  'Predecessor',
  'Successor',
  'Alternative Source(s)',
  'References',
];

initialMatrix[0] = headers;

initialMatrix[1] = new Array(headers.length).fill('');
initialMatrix[2] = new Array(headers.length).fill('');
initialMatrix[3] = new Array(headers.length).fill('');

const deleteAndShiftColumnByRowIndex = (
  matrix: ReactNode[][],
  rowIndex: number,
) => {
  const updatedMatrix = matrix.map((row, i) => {
    if (i >= rowIndex) {
      const newRow = [...row];
      for (let j = 0; j < newRow.length - 1; j++) {
        newRow[j] = matrix[i + 1] ? matrix[i + 1][j] : '';
      }
      newRow[newRow.length - 1] = ''; // Set the last element to initial state object
      return newRow;
    }
    return row;
  });
  return updatedMatrix;
};
const PartCompare = () => {
  const groups = useGroups();
  const { setArea, area } = useContext(AreaContext);
  const [matrix, setMatrix] = useState<ReactNode[][]>(initialMatrix);
  const { part_id } = useParams();
  const [searchParams] = useSearchParams();
  let status = searchParams.get('status');

  //If status is not a required one change it to empty
  if (status !== 'PENDING' && status !== 'POSTPONED') status = '';

  const canEdit =
    !status &&
    (groups ? groups.includes(import.meta.env.VITE_AZURE_ADMIN_GROUP) : false);

  const [tableSize] = useState<TTableSize>('small');

  const { data, makeApiCall } = useApiCall<TPart>();

  /* 
New unit info - removed by client
<>
        <PartLifeTable
          key="partlife"
          partlife={value.part_lifes}
          editable={canEdit}
          size={tableSize}
          bom={value.title.bom}
        />

        <div style={{ marginTop: 10 }}>
          <Typography tag="textsmall_strong">
            Maintenance Concepts (new unit)
          </Typography>
          <PartDetailMaintenance
            key="maintenance"
            value={value.maintenance_concepts}
            editable={canEdit}
            bom={value.title.bom}
          />
        </div>
      </>, */

  const handleBOM = (compareIndex: number, value: TPart) => {
    const PartArray = [
      <Typography key="title" tag="textdefault_strong">
        {`${value.title.bom} (${value.title.frame_family} - ${value.title.part_type})`}
      </Typography>,
      <PartDetailDescription
        key="description"
        value={value.description}
        bom={value.title.bom}
        editable={canEdit}
      />,
      <PartDetailEarmarks
        key="earmarks"
        value={value.earmarks}
        editable={canEdit}
        bom={value.title.bom}
      />,
      <PartDetailOwner key="part_owners" value={value.part_owners} />,
      <PartStatusTable
        key="status"
        status={value.status}
        size={tableSize}
        editable={canEdit}
        bom={value.title.bom}
      />,
      <PartSetCountTable
        key="setcount"
        setcount={value.set_part_count}
        size={tableSize}
      />,
      <PartMaterialTable
        key="material"
        material={value.material}
        size={tableSize}
      />,

      <PartDetailCastingVendors
        key="casting_vendors"
        value={value.casting_vendors}
      />,
      <PartDetailDesignFeatures
        key="design_features"
        value={value.design_features}
        editable={canEdit}
        bom={value.title.bom}
      />,
      <PartDetailApplications key="applications" value={value.applications} />,
      <PartScrapRatesTable
        key="scrapRates"
        scrapRates={value.scrap_rates}
        size={tableSize}
      />,
      <PartApplicationMatrixTable
        key="partlife"
        applicationmatrix={value.application_matrix_incoming}
        size="extra-small"
        compare
      />,

      <PartDetailPredecessors
        key="predecessors"
        value={value.predecessors}
        editable={canEdit}
        bom={value.title.bom}
      />,
      <PartDetailSuccessors key="sucessors" value={value.predecessors} />,
      <PartDetailAlternativeSources
        key="alternative_sources"
        value={value.alternative_sources}
      />,

      <PartDetailReferences
        key="references"
        value={value.references}
        bom={value.title.bom}
        editable={canEdit}
      />,
    ];

    setMatrix((prevState) => {
      const updatedMatrix = prevState.map((row, index) => {
        if (index === compareIndex) {
          return PartArray;
        }
        return row;
      });
      return updatedMatrix;
    });
  };

  const handleDelete = (compareIndex: number) => {
    setMatrix(deleteAndShiftColumnByRowIndex(matrix, compareIndex));
  };

  useEffect(() => {
    if (!part_id) return;
    makeApiCall({
      method: 'GET',
      url:
        import.meta.env.VITE_APP_API_URL +
        '/part/' +
        part_id +
        (status && '?status=' + status),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part_id]);

  useEffect(() => {
    if (data) {
      handleBOM(1, data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (area !== 'parts') setArea('parts');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MatrixGrid = ({ matrix }: MatrixProps) => {
    const transposeMatrix = (m: ReactNode[][]) =>
      m[0].map((_, i) => m.map((row) => row[i]));

    const transposedMatrix = transposeMatrix(matrix);

    const calculateWidth = (matrix: ReactNode[][]) => {
      if (matrix[3][0]) {
        return '33%';
      } else if (matrix[2][0]) {
        return '50%';
      } else if (matrix[1][0]) {
        return '100%';
      } else {
        return '100%'; // Default value if none of the conditions are met
      }
    };

    return (
      <table style={{ width: '100%' }}>
        <tbody>
          {transposedMatrix.map((column, columnIndex) => (
            <tr key={columnIndex}>
              {column.map(
                (cell, rowIndex) =>
                  (rowIndex === 0 ||
                    rowIndex === 1 ||
                    (rowIndex > 1 && column[rowIndex - 1])) && (
                    <td
                      valign="top"
                      key={rowIndex}
                      style={{
                        minWidth: rowIndex > 0 ? '200px' : '',
                        width: rowIndex === 0 ? '80px' : calculateWidth(matrix),
                        padding: 20,
                        borderBottom: 'solid 1px #ededed',
                        borderLeft: rowIndex > 0 ? 'solid 1px #ededed' : '',
                      }}
                      className={columnIndex === 0 ? 'sticky' : ''}
                    >
                      {columnIndex === 0 && rowIndex > 0 && cell && (
                        <div style={{ float: 'right' }}>
                          <ButtonIcon
                            icon="IconClose"
                            size="small"
                            hoverType="color"
                            onClick={() => handleDelete(rowIndex)}
                          />
                        </div>
                      )}

                      {rowIndex === 0 ? (
                        <Typography tag="textsmall_strong">{cell}</Typography>
                      ) : (
                        cell
                      )}
                      {columnIndex === 0 && rowIndex !== 0 && !cell && (
                        <BOM cb={handleBOM} compareIndex={rowIndex} />
                      )}
                    </td>
                  ),
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <div className="contentContainer">
        <div>
          <Stack direction="row" gap={5} verticalAlign="center">
            <Link to="/parts">Parts</Link>
            <Icon icon="IconCaretRight" iconSize={0.7} color="#666666" />

            {part_id ? (
              <>
                <Link to={'/parts/detail/' + part_id}>{part_id}</Link>
                <Icon icon="IconCaretRight" iconSize={0.7} color="#666666" />
              </>
            ) : null}
          </Stack>
          <Typography tag="h3">Compare</Typography>

          <Divider orientation="horizontal" padding={20} />
        </div>
        <Typography tag="textsmall">
          <MatrixGrid matrix={matrix} />
        </Typography>
      </div>
    </>
  );
};

export default PartCompare;
