import { Table } from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TPartApplicationMatrix } from '../../types';
import { TTableSize } from '../PartDetailTables';
import styles from './../PartDetailTables.module.scss';
type TPartApplicationMatrixTable = {
  applicationmatrix: Partial<TPartApplicationMatrix>[];
  size?: TTableSize;
  compare?: boolean;
};
const PartApplicationMatrixTable = ({
  applicationmatrix,
  compare = false,
  size = 'normal',
}: TPartApplicationMatrixTable) => {
  let headers: TableHeaderType[] = [];
  if (compare) {
    headers = [
      {
        align: 'left',
        title: 'Main Cluster',
      },
      {
        align: 'center',
        title: 'Incoming Set-Style',
      },
      {
        align: 'center',
        title: 'Incoming Sub-Style',
      },
      {
        align: 'center',
        title: 'Characteristic [in]',
        columnWidth: '150px',
      },
      {
        align: 'center',
        title: 'Life Cluster [in]',
      },
      {
        align: 'center',
        title: 'Assembly Cluster [in]',
      },

      {
        align: 'center',
        title: 'Status',
      },
    ];
  } else {
    headers = [
      {
        align: 'left',
        title: 'Main Cluster',
      },
      {
        align: 'center',
        title: 'Incoming Set-Style',
      },
      {
        align: 'center',
        title: 'Incoming Sub-Style',
      },
      {
        align: 'center',
        title: 'Characteristic [in]',
        columnWidth: '150px',
      },
      {
        align: 'center',
        title: 'Life Cluster [in]',
      },
      {
        align: 'center',
        title: 'Assembly Cluster [in]',
      },

      {
        align: 'center',
        title: 'Outcoming Set-Style',
      },
      {
        align: 'center',
        title: 'Outcoming Sub-Style',
      },
      {
        align: 'center',
        title: 'Characteristic [out]',
        columnWidth: '150px',
      },
      {
        align: 'center',
        title: 'Life Cluster [out]',
      },
      {
        align: 'center',
        title: 'Assembly Cluster [out]',
      },
      {
        align: 'center',
        title: 'Status',
      },
    ];
  }

  const rows: TableRowData[] = applicationmatrix.map((item, index: number) => {
    if (compare) {
      return {
        data: {
          main_cluster: item.main_cluster,
          incoming_set_style: item.incoming_set_style,
          incoming_sub_style: item.incoming_sub_style,
          characteristic_in: item.characteristic_in,
          life_cluster_in: (
            <div
              className={styles['matrix-cell']}
              style={{
                backgroundColor: item.life_cluster_in_color,
                justifyContent: 'center',
              }}
            >
              {item.life_cluster_in}
            </div>
          ),
          assembly_cluster_in: (
            <div
              className={styles['matrix-cell']}
              style={{
                backgroundColor: item.assembly_cluster_in_color,
                justifyContent: 'center',
              }}
            >
              {item.assembly_cluster_in}
            </div>
          ),

          status: item.status,
        },
        id: `row_${index}`,
      };
    } else {
      return {
        data: {
          main_cluster: item.main_cluster,
          incoming_set_style: item.incoming_set_style,
          incoming_sub_style: item.incoming_sub_style,
          characteristic_in: item.characteristic_in,
          life_cluster_in: (
            <div
              className={styles['matrix-cell']}
              style={{
                backgroundColor: item.life_cluster_in_color,
                justifyContent: 'center',
              }}
            >
              {item.life_cluster_in}
            </div>
          ),
          assembly_cluster_in: (
            <div
              className={styles['matrix-cell']}
              style={{
                backgroundColor: item.assembly_cluster_in_color,
                justifyContent: 'center',
              }}
            >
              {item.assembly_cluster_in}
            </div>
          ),
          outcoming_set_style: item.outcoming_set_style,
          outcoming_sub_style: item.outcoming_sub_style,
          characteristic_out: item.characteristic_out,
          life_cluster_out: (
            <div
              className={styles['matrix-cell']}
              style={{
                backgroundColor: item.life_cluster_out_color,
                justifyContent: 'center',
              }}
            >
              {item.life_cluster_out}
            </div>
          ),
          assembly_cluster_out: (
            <div
              className={styles['matrix-cell']}
              style={{
                backgroundColor: item.assembly_cluster_out_color,
                justifyContent: 'center',
              }}
            >
              {item.assembly_cluster_out}
            </div>
          ),
          status: item.status,
        },
        id: `row_${index}`,
      };
    }
  });

  return (
    <Table
      headerVerticalAlign="bottom"
      headers={headers}
      rows={rows}
      indentation={0}
      typographyTag={
        size === 'normal'
          ? 'textdefault'
          : size === 'extra-small'
          ? 'textextrasmall'
          : 'textsmall'
      }
    />
  );
};

export default PartApplicationMatrixTable;
