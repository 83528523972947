import { Spinner } from '@/components/Spinner';
import { searchParametersWithEndpointLabel } from '@/contexts/AreaContext/defaultObjects';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  ButtonIcon,
  Divider,
  Select,
  SelectOption,
  Stack,
} from '@data-products-and-ai/react-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { TPart, TPartDesignFeature, TPartWithIdOrdinal } from '../types';
import styles from './PartDetail.module.scss';
type TPartDetailItem = {
  value: TPart['design_features'];
  bom: string;
  editable: boolean;
};
const PartDetailDesignFeatures = ({
  value,
  bom,
  editable = true,
}: TPartDetailItem) => {
  const [currentEdits, setCurrentEdits] = useState<number[]>([]);
  const [dynamicValue, setDynamicValue] = useState(value);

  const { data, makeApiCall } = useApiCall<{
    [key: string]: TPartWithIdOrdinal[];
  }>();

  useEffect(() => {
    makeApiCall({
      method: 'GET',
      url:
        import.meta.env.VITE_APP_API_URL +
        '/' +
        searchParametersWithEndpointLabel.design_feature.endpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!dynamicValue) return;

  type TEarmarkList = {
    id?: number;
    designFeatureId?: number;
    setDynamicValue?: React.Dispatch<
      React.SetStateAction<TPartDesignFeature[]>
    >;
    setCurrentEdits?: React.Dispatch<React.SetStateAction<number[]>>;
  };
  type TEarmarkUpdate = TEarmarkList & {
    designFeatureValue: string;
    designFeatureOrdinal: string;
  };
  type TEarmarkDelete = {
    id: number;
  };

  const earmarkUpdate = (props: TEarmarkUpdate) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'PUT',
          useAsFetch: true,
          url:
            import.meta.env.VITE_APP_API_URL +
            `/part/${bom}/` +
            searchParametersWithEndpointLabel.design_feature.endpoint,
          payload: { id: props.id, design_feature_id: props.designFeatureId },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        if (result.status && result.status !== 200) {
          alert("ERROR: Design feature couldn't be updated");
          return;
        }

        setDynamicValue((prevState) =>
          prevState.map((item) =>
            item.id === props.id
              ? {
                  ...item,
                  design_feature_id: props.designFeatureId as number,
                  design_feature: props.designFeatureValue,
                  ordinal: props.designFeatureOrdinal,
                }
              : item,
          ),
        );
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const Insert = (props: Partial<TEarmarkUpdate>) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'POST',
          useAsFetch: true,
          url:
            import.meta.env.VITE_APP_API_URL + `/part/${bom}/design_features`,
          payload: {
            design_feature_id: props.designFeatureId
              ? props.designFeatureId.toString()
              : '',
            design_feature: props.designFeatureValue,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== 0),
        );

        if (result.status && result.status !== 200) {
          //error
          setDynamicValue((prev) => prev.filter((item) => item.id !== 0));

          alert("ERROR: Design Feature couldn't be inserted");
          return;
        }

        setDynamicValue((prevState) =>
          prevState.map((item) =>
            item.id === 0
              ? {
                  ...item,
                  id: result.ID,
                  design_feature_id: props.designFeatureId
                    ? props.designFeatureId
                    : 0,
                  design_feature: props.designFeatureValue as string,
                  ordinal: props.designFeatureOrdinal as string,
                }
              : item,
          ),
        );
      })
      .catch(() => {
        setDynamicValue((prev) => prev.filter((item) => item.id !== 0));
        alert('Something went wrong');
      });
  };

  const earmarkDelete = (props: TEarmarkDelete) => {
    if (
      confirm('Are you sure you want to delete this design feature?') === true
    ) {
      const ApiCall = async () => {
        try {
          return await makeApiCall({
            method: 'DELETE',
            useAsFetch: true,
            url:
              import.meta.env.VITE_APP_API_URL +
              `/part/${bom}/` +
              searchParametersWithEndpointLabel.design_feature.endpoint,
            payload: { record_id: props.id },
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          console.error('Error occurred:', error);

          throw error;
        }
      };

      ApiCall()
        .then((result) => {
          if (result.status && result.status !== 200) {
            alert("ERROR: Design Feature couldn't be deleted");
            return;
          }

          setDynamicValue((prevState) =>
            prevState.filter((item) => item.id !== props.id),
          );
        })
        .catch(() => {
          alert('Something went wrong');
        });
    }
  };

  const EarmarkList = (props: TEarmarkList) => {
    const [publish, setPublish] = useState(false);
    if (!data) return;

    if (publish) return <Spinner borderSize={2} size={16} color="#CCCCCC" />;

    const Cancel = () => {
      if (props.setCurrentEdits) {
        props.setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== props.id),
        );
      }

      //New row
      if (props.id === 0 && props.setDynamicValue) {
        props.setDynamicValue((prev) =>
          prev.filter((item) => item.id !== props.id),
        );
      }
    };

    return (
      <>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '400px 100px',
            gap: 10,
            paddingBottom: 5,
            paddingTop: 5,
          }}
        >
          <Select
            placeholder="Select design feature"
            size="small"
            defaultValue={
              props.designFeatureId
                ? props.designFeatureId.toString()
                : undefined
            }
            id={
              props.designFeatureId
                ? props.designFeatureId.toString()
                : 'AddDesignFeature'
            }
            onChange={(event) => {
              setPublish(true);
              setCurrentEdits((prevNumbers) =>
                prevNumbers.filter((number) => number !== props.id),
              );

              const selectedValue =
                event.target.options[event.target.selectedIndex].text;
              const parts = selectedValue.split(' - ');
              if (props.id === 0) {
                Insert({
                  id: props.id,
                  designFeatureId: parseInt(event.target.value),
                  designFeatureValue: parts[1].trim(),
                  designFeatureOrdinal: parts[0].trim(),
                });
              } else {
                earmarkUpdate({
                  id: props.id,
                  designFeatureId: parseInt(event.target.value),
                  designFeatureValue: parts[1].trim(),
                  designFeatureOrdinal: parts[0].trim(),
                });
              }
            }}
          >
            {data.design_features.map((el) => {
              return (
                <SelectOption
                  key={el.id}
                  label={el.ordinal + ' - ' + el.value}
                  value={el.id.toString()}
                />
              );
            })}
          </Select>
          <div>
            <ButtonIcon
              hoverType="color"
              icon="IconClose"
              size="extra-small"
              tooltipMessage="Cancel"
              onClick={Cancel}
            />
          </div>
        </div>
      </>
    );
  };

  type TEditButton = {
    id: number;
  };
  const EditButton = (props: TEditButton) => {
    return (
      <div className={styles.edit}>
        <div style={{ display: 'inline-block', marginLeft: 20 }}>
          <Stack direction="row" gap={10}>
            <Button
              type="link"
              allCaps={false}
              onClick={() =>
                setCurrentEdits((prevNumbers) => [...prevNumbers, props.id])
              }
            >
              Edit
            </Button>

            <Divider orientation="vertical" />
            <Button
              type="link"
              allCaps={false}
              onClick={() => earmarkDelete({ id: props.id })}
            >
              Delete
            </Button>
          </Stack>
        </div>
      </div>
    );
  };

  const addNewRow = () => {
    setCurrentEdits((prevNumbers) => [...prevNumbers, 0]);
    setDynamicValue((prev) => [
      ...prev,
      { id: 0, design_feature_id: 0, design_feature: '', ordinal: '' },
    ]);
  };

  return (
    <>
      <ul className={styles['ul-none']}>
        {dynamicValue.map((earmark: TPartDesignFeature) => (
          <div
            key={earmark.id}
            className={classNames(styles['li-none'], styles['li-border'])}
          >
            {currentEdits.includes(earmark.id) ? (
              <EarmarkList
                id={earmark.id}
                designFeatureId={earmark.design_feature_id}
                setCurrentEdits={setCurrentEdits}
                setDynamicValue={setDynamicValue}
              />
            ) : (
              <div className={styles.editableContent}>
                {earmark.ordinal} - {earmark.design_feature}
                {editable && <EditButton id={earmark.id} />}
              </div>
            )}
          </div>
        ))}
        {editable && (
          <li key="DesignFeatureAdd" className={styles['li-none']}>
            {!currentEdits.includes(0) && (
              <Button
                type="primaryOutline"
                size="small"
                onClick={() => addNewRow()}
              >
                Add design feature
              </Button>
            )}
          </li>
        )}
      </ul>
    </>
  );
};

export default PartDetailDesignFeatures;
