import { Table } from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TPartScrapRates } from '../../types';
import { TTableSize } from '../PartDetailTables';

type TPartScrapRatesTable = {
  scrapRates: TPartScrapRates[];
  size?: TTableSize;
};

const PartScrapRatesTable = ({
  scrapRates,
  size = 'normal',
}: TPartScrapRatesTable) => {
  const headers: TableHeaderType[] = [
    {
      align: 'left',
      title: 'Ambient Temp',
    },
    {
      align: 'left',
      title: 'Concept',
    },
    {
      align: 'center',
      title: 'Tit ISO',
    },
    {
      align: 'left',
      title: 'Applications',
    },
    {
      align: 'center',
      title: 'Max Hours',
    },
    {
      align: 'center',
      title: 'Max Starts',
    },
    {
      align: 'center',
      title: 'Max Repair',
    },
  ];

  const rows: TableRowData[] = scrapRates.map((item) => {
    return {
      data: {
        ambient_temp: item.ambient_temp,
        concept: item.concept,
        tit_iso: item.tit_iso ?? '-',
        applications: item.applications ?? '-',

        mrl_hrs: item.mrl_hrs ?? '-',
        mrl_strts: item.mrl_strts ?? '-',
        mrl_rpr: item.mrl_rpr ?? '-',
      },
      id: item.bom + item.ambient_temp + item.concept,
    };
  });

  return (
    <Table
      headerVerticalAlign="bottom"
      headers={headers}
      rows={rows}
      indentation={0}
      typographyTag={
        size === 'normal'
          ? 'textdefault'
          : size === 'extra-small'
          ? 'textextrasmall'
          : 'textsmall'
      }
    />
  );
};

export default PartScrapRatesTable;
