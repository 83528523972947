import { Divider, Typography } from '@data-products-and-ai/react-components';

const About = () => {
  return (
    <>
      <div className="contentContainer" style={{ marginTop: 20 }}>
        <div>
          <p>
            The BLISS - Turbine Blades and Vanes Information System and Services
            - is a look-up tool for all design variants of turbine main parts of
            Siemens Energy LGTs (Large Gas Turbines), such as blades, vanes and
            ring segments. It provides material specifications, application
            options and correlations between parts.
          </p>
          {/*  <p>
          Blade Information Systems & Services (BLISS) is a database providing
            part information for turbine blades and vanes. The information
            contained in BLISS is collected from OneP and complemented with
            additional information collected from the respective PDP documents,
            in particular: part design lifes under certain operational regimes
            and associated maintenance concepts, part pedigree and part
            equivalence as well as spare part recommendations.

            While we attempt to ensure the accuracy of the information contained
            in BLISS, the user is cautioned that the official records, most
            notably the ones from OneP, shall prevail in the event of any errors
            and/or omissions contained in BLISS.
          </p> */}
          <Typography tag="textsmall_strong">Disclaimer</Typography>
          No warranties, either expressed or implied whatsoever are provided for
          the accuracy of data contained in BLISS, its use or its
          interpretation. Although information is updated periodically, the
          information contained in BLISS may not accurately reflect the most
          current data available.
        </div>

        <Divider padding={50} />
        <div>
          <div
            style={{
              paddingBottom: 20,
            }}
          >
            <Typography tag="textdefault">
              For more details on the content please contact responsible Part
              Owner, as assigned indiviually in detail part view. In case of
              questions regarding the tool please contact Verena Kaus.
            </Typography>
          </div>

          <div
            style={{
              padding: 20,
              borderRadius: 5,
              border: 'solid 1px #CCC',
              display: 'inline-block',
            }}
          >
            <div>
              <Typography tag="textlarge_strong">
                <a href="mailto:verena.kaus@siemens-energy.com">Verena Kaus</a>
              </Typography>
            </div>
            <div>Muelheim an der Ruhr, Germany</div>
            <div>SE GS C LGT EN MOD TUR OPS2</div>
            <div>
              <a href="mailto:verena.kaus@siemens-energy.com">
                verena.kaus@siemens-energy.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
