import { Spinner } from '@/components/Spinner';
import { CONSTANTS } from '@/constants/constants';
import { AreaContext } from '@/contexts/AreaContext';
import useApiCall from '@/hooks/useApiCall';
import {
  AppStoreCounter,
  Button,
  Icon,
  Input,
  Stack,
  Typography,
  useTranslation,
  useUserDetails,
} from '@data-products-and-ai/react-components';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './Home.module.scss';
import homeBackground from './homeBackground.jpg';

type TLinkOption = {
  link: string;
  value: string;
};
const LinkOption = (props: TLinkOption) => {
  return (
    <div className={styles.row}>
      <Stack direction="row" verticalAlign="center" gap={5}>
        <Icon icon="IconCaretRight" iconSize={0.8} />
        <Link to={props.link}>{props.value}</Link>
      </Stack>
    </div>
  );
};

const GetFrames = () => {
  const { isLoading, error, data, makeApiCall } = useApiCall();
  useEffect(() => {
    makeApiCall({
      method: 'GET',
      url: import.meta.env.VITE_APP_API_URL + '/frames',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const framesList = useMemo(() => {
    if (data && data.frames) {
      const sgt5Frames: string[] = [];
      const sgt6Frames: string[] = [];
      const otherFrames: string[] = [];

      data.frames.forEach((item: string) => {
        if (item.startsWith('SGT5')) {
          sgt5Frames.push(item);
        } else if (item.startsWith('SGT6')) {
          sgt6Frames.push(item);
        } else {
          otherFrames.push(item);
        }
      });

      return (
        <div
          className={styles.columnsContainer}
          style={{ gridTemplateColumns: '1fr 1fr 1fr' }}
        >
          <div className={styles.column}>
            <div className={styles.columnHeader}>
              <Typography tag="textdefault">50 Hz</Typography>
            </div>

            {sgt5Frames.map((item) => (
              <LinkOption
                key={`FrameLinkOption_${item}`}
                link={`/frames/${item}`}
                value={item}
              />
            ))}
          </div>
          <div className={styles.column}>
            <div className={styles.columnHeader}>
              <Typography tag="textdefault">60 Hz</Typography>
            </div>

            {sgt6Frames.map((item) => (
              <LinkOption
                key={`FrameLinkOption_${item}`}
                link={`/frames/${item}`}
                value={item}
              />
            ))}
          </div>
          <div className={styles.column}>
            <div className={styles.columnHeader}>
              <Typography tag="textdefault">Other</Typography>
            </div>
            {otherFrames.map((item) => (
              <LinkOption
                key={`FrameLinkOption_${item}`}
                link={`/frames/${item}`}
                value={item}
              />
            ))}
          </div>
        </div>
      );
    }
    return null;
  }, [data]);

  if (isLoading) {
    return (
      <div
        className="contentContainer"
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner borderSize={4} size={32} color="#CCCCCC" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
      {framesList}
    </div>
  );
};

const Home = () => {
  const { area, setArea } = useContext(AreaContext);
  const [universalSearch, setUniversalSearch] = useState<string>('');
  const { gid } = useUserDetails();
  const translate = useTranslation();

  document.title = CONSTANTS.en.TITLE;

  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (universalSearch.trim().length > 0)
      navigate('/parts?text=' + universalSearch);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setUniversalSearch(value);
  };

  useEffect(() => {
    if (area !== 'home') setArea('home');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppStoreCounter appID="10122" GID={gid} />
      <div
        style={{
          backgroundColor: '#000066',
          height: 240,
          color: '#FFFFFF',
          padding: 30,
          backgroundImage: `url(${homeBackground})`,
          backgroundSize: 'cover',

          backgroundPosition:
            'left center' /* Align background to the right and vertically centered */,
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div style={{ width: 1000 }}>
          <div style={{ lineHeight: 1.8 }}>
            <Typography tag="h1">{translate('HOME.WELCOME_TITLE')}</Typography>

            <Typography tag="textdefault">
              {translate('HOME.WELCOME_DESCRIPTION')}
            </Typography>
          </div>
        </div>
      </div>

      <div
        style={{ backgroundColor: '#fafafa', padding: 30, textAlign: 'center' }}
      >
        <div style={{ marginBottom: 20 }}>
          <Typography tag="h1">Universal search bar</Typography>
        </div>

        <form onSubmit={handleSubmit}>
          <Stack direction="row" gap={20} horizontalAlign="center">
            <div style={{ width: 600 }}>
              <Input
                id="searchUniversal"
                autofocus
                size="large"
                placeholder="Search for any stored value, not only BOM #"
                defaultValue={universalSearch}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <Button buttonType="submit">Search</Button>
          </Stack>
        </form>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          paddingLeft: 30,
          paddingRight: 30,
          gap: 30,
        }}
      >
        <div style={{ backgroundColor: '#FFFFFF', padding: 20 }}>
          <Typography tag="h3">Parts</Typography>
          <div className={styles.columnsContainer}>
            <LinkOption link="/parts" value="Search" />
            <LinkOption link="/parts/compare" value="Compare" />
            <LinkOption
              link="/parts/part_development"
              value="Part Development"
            />
          </div>
        </div>
        <div style={{ backgroundColor: '#FFFFFF', padding: 20 }}>
          <Typography tag="h3">Frames</Typography>
          <GetFrames />
        </div>
        <div style={{ backgroundColor: '#FFFFFF', padding: 20 }}>
          <Typography tag="h3">We are BLISS</Typography>
          <div className={styles.columnsContainer}>
            <LinkOption link="/about" value="About" />
            <LinkOption link="/administration" value="User management" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
