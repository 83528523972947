import useApiCall from '@/hooks/useApiCall';
import { Autocomplete } from '@data-products-and-ai/react-components';
import { useEffect, useMemo, useState } from 'react';

type TBOM = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cb?: (...args: any[]) => void;
  compareIndex?: number;
  placeholder?: string;
};

const Bom = ({ placeholder = 'Select a BOM', ...props }: TBOM) => {
  const [boms, setBoms] = useState<string[]>([]);
  const [selectedBom, setSelectedBom] = useState<string | null>(null);
  const { isLoading, error, makeApiCall } = useApiCall<string>();

  useEffect(() => {
    const getBomList = async () => {
      const BOM_List = await makeApiCall({
        useAsFetch: true,
        method: 'GET',
        url: import.meta.env.VITE_APP_API_URL + '/boms',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setBoms(BOM_List.boms);
    };

    getBomList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedBom) return;
    if (selectedBom) {
      const getPart = async () => {
        const PartDetail = await makeApiCall({
          useAsFetch: true,
          method: 'GET',
          url: import.meta.env.VITE_APP_API_URL + '/part/' + selectedBom,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (props.cb) {
          if (props.compareIndex) {
            props.cb(props.compareIndex, PartDetail);
          } else {
            props.cb && props.cb(PartDetail);
          }
        }

        /*    const bomArray = BOM_List.parts.map((item: TPartList) => item.bom).sort();
        setBoms(bomArray); */
      };

      getPart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBom]);

  const memoizedComponent = useMemo(() => {
    if (error) return 'Error';

    return (
      <>
        <Autocomplete
          placeholder={placeholder}
          size="small"
          onChange={(value) => {
            if (value && value[0]) {
              setSelectedBom(value[0].key);
            }
          }}
          selected={[]}
          id="Bom_list"
          options={boms.map((el: string) => {
            return {
              key: el,
              label: el,
            };
          })}
        />
      </>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, error, boms]);

  return memoizedComponent;
};

export default Bom;
