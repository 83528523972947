import { Button } from '@data-products-and-ai/react-components';

const Administration = () => {
  return (
    <div className="contentContainer">
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50vh',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 300,
            width: 400,
            padding: 30,
            border: '1px solid #ededed',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            You can easily grant administrative privileges to specific users,
            enabling them to effectively manage and oversee BLISS content.
            <br />
            <br />
            <Button
              onClick={() =>
                window.open(
                  'https://myaccount.microsoft.com/groups/groups-i-own',
                )
              }
            >
              Manage Permissions
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Administration;
