import styles from './Spinner.module.scss';

type TSpinner = {
  inline?: boolean;
  size?: number;
  borderSize?: number;
  backgroundColor?: string;
  color?: string;
  text?: string;
};
const Spinner = ({
  inline = true,
  size = 16,
  borderSize = 9,
  backgroundColor = '#f0f0f0',
  color = '#8a00e5',
}: TSpinner) => {
  const SpinIcon = () => (
    <div
      className={styles.spinner}
      style={{
        display: inline ? 'inline-block' : 'block',
        width: size,
        height: size,
        border: `${borderSize}px solid`,
        borderColor: backgroundColor,
        borderRightColor: color,
      }}
    ></div>
  );

  return (
    <>
      <SpinIcon />
    </>
  );
};

export default Spinner;
