import { Spinner } from '@/components/Spinner';
import { AreaContext } from '@/contexts/AreaContext';
import { TSearchParametersWithEndpointLabel } from '@/contexts/AreaContext/AreaContext';
import { searchParametersWithEndpointLabel } from '@/contexts/AreaContext/defaultObjects';
import useApiCall from '@/hooks/useApiCall';
import { TPartWithId } from '@/pages/Part/types';
import {
  Autocomplete,
  Label,
  Select,
  SelectOption,
} from '@data-products-and-ai/react-components';
import { MenuOption } from '@data-products-and-ai/react-components/lib/components/Forms/Autocomplete/Autocomplete';
import { useContext, useEffect } from 'react';

type TSearchItem = {
  itemKey: keyof TSearchParametersWithEndpointLabel;
  multipleSelection?: boolean;
  personalClassification?: boolean;
  disabled?: boolean;
};

/* type TSearchItemResp = {
  id: string;
  value: string;
}; */

const SearchItem = ({
  multipleSelection = true,
  personalClassification = false,
  disabled = false,
  ...props
}: TSearchItem) => {
  const {
    searchParameters,
    setSearchParameters,
    personalParameters,
    setPersonalParameters,
  } = useContext(AreaContext);
  const { isLoading, error, data, makeApiCall } = useApiCall<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  }>();

  useEffect(() => {
    makeApiCall({
      method: 'GET',
      url:
        import.meta.env.VITE_APP_API_URL +
        '/' +
        searchParametersWithEndpointLabel[props.itemKey].endpoint,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Spinner borderSize={2} size={16} color="#CCCCCC" />;
  if (error) return 'Error';

  if (data) {
    return (
      <div>
        <Label>{searchParametersWithEndpointLabel[props.itemKey].label}</Label>

        {multipleSelection ? (
          <Autocomplete
            placeholder={searchParametersWithEndpointLabel[props.itemKey].label}
            size="small"
            selected={
              personalClassification
                ? personalParameters[props.itemKey]
                  ? personalParameters[props.itemKey]
                      .split(',')
                      .map((value) => ({
                        key: value,
                        label: searchParametersWithEndpointLabel[props.itemKey]
                          .has_id
                          ? data[
                              searchParametersWithEndpointLabel[props.itemKey]
                                .endpoint
                            ].find(
                              (el: TPartWithId) => el.id.toString() === value,
                            )?.value
                          : value,
                      }))
                  : []
                : searchParameters[props.itemKey]
                ? searchParameters[props.itemKey].split(',').map((value) => ({
                    key: value,
                    label: searchParametersWithEndpointLabel[props.itemKey]
                      .has_id
                      ? data[
                          searchParametersWithEndpointLabel[props.itemKey]
                            .endpoint
                        ].find((el: TPartWithId) => el.id.toString() === value)
                          ?.value
                      : value,
                  }))
                : []
            }
            multiple
            onChange={(value) => {
              const commaSeparatedString = value
                .map((item) => item.key)
                .join(',');

              personalClassification
                ? setPersonalParameters((prevState) => ({
                    ...prevState,
                    [props.itemKey]: commaSeparatedString,
                  }))
                : setSearchParameters((prevState) => ({
                    ...prevState,
                    [props.itemKey]: commaSeparatedString,
                  }));

              /* setSearchParameters((prevState) => ({
                ...prevState,
                [props.itemKey]: commaSeparatedString,
              })); */
            }}
            id={props.itemKey}
            options={
              searchParametersWithEndpointLabel[props.itemKey].has_id
                ? data[
                    searchParametersWithEndpointLabel[props.itemKey].endpoint

                    //FIXME: Change any to correct type
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ].map((el: any) => {
                    const temp: MenuOption = {
                      key: el.id.toString(),
                      label: el.value,
                    };
                    return temp;
                  })
                : data[
                    searchParametersWithEndpointLabel[props.itemKey].endpoint
                  ].map((el: string) => {
                    const temp: MenuOption = {
                      key: el,
                      label: el,
                    };
                    return temp;
                  })
            }
          />
        ) : (
          <Select
            size="small"
            disabled={disabled}
            defaultValue={
              personalClassification
                ? personalParameters[props.itemKey][0]
                : searchParameters[props.itemKey]
            }
            id={props.itemKey}
            onChange={(event) => {
              personalClassification
                ? setPersonalParameters((prevState) => ({
                    ...prevState,
                    [props.itemKey]: event.target.value,
                  }))
                : setSearchParameters((prevState) => ({
                    ...prevState,
                    [props.itemKey]: event.target.value,
                  }));
            }}
            placeholder="All"
            selectablePlaceholder
          >
            {searchParametersWithEndpointLabel[props.itemKey].has_id
              ? data[
                  searchParametersWithEndpointLabel[props.itemKey].endpoint

                  //FIXME: Change any to correct type
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ].map((el: any) => {
                  return (
                    <SelectOption
                      key={el.value}
                      label={el.value}
                      value={el.id.toString()}
                    />
                  );
                })
              : data[
                  searchParametersWithEndpointLabel[props.itemKey].endpoint
                ].map((el: string) => {
                  return <SelectOption key={el} label={el} value={el} />;
                })}
          </Select>
        )}
      </div>
    );
  }

  return '';
};

export default SearchItem;
