import {
  Button,
  Logo,
  Stack,
  Typography,
  useTranslation,
} from '@data-products-and-ai/react-components';
import styles from './Login.module.scss';
import Background from './assets/background.jpg';
import { AzureLoginButton } from './components/AzureLoginButton';
import { LoginTitle } from './components/LoginTitle';

const Login = () => {
  const translate = useTranslation();

  const handleEmail = () => {
    const mail = document.createElement('a');
    mail.href = `mailto:verena.kaus@siemens-energy.com?subject=${translate(
      'LOGIN.REQUEST_ACCESS_EMAIL_SUBJECT',
    )}`; /* ${LOGIN.REQUEST_ACCESS_EMAIL_SUBJECT} */
    mail.click();
    mail.remove();
  };

  const handleTicket = () => {
    window.open(
      'https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/e82a61a6-259f-46c7-8ae8-0664b36888e1?hidenavbar=true&Lookup=43',
    );
  };

  return (
    <div
      className={styles.loginContainer}
      style={
        {
          '--bg-image': `url(${Background})`,
        } as React.CSSProperties
      }
    >
      <div className={styles.loginFormContainer}>
        <Logo background="light" size="medium" />
        <LoginTitle />

        <div style={{ marginTop: 40 }}>
          <AzureLoginButton />

          <div style={{ marginTop: 40 }}>
            <Typography tag="textsmall_strong">
              <Stack direction="row" inline gap={5}>
                Please contact
                <Button type="link" onClick={handleEmail} allCaps={false}>
                  Verena Kaus
                </Button>
                for registration.
              </Stack>
            </Typography>
          </div>
        </div>

        <div className={styles.loginFormAdditional}>
          {/* Make_reusable divider  */}
          <hr />

          <Stack direction="row" horizontalAlign="center">
            <Button type="link" onClick={handleEmail} allCaps={false}>
              {translate('LOGIN.REQUEST_ACCESS')}
            </Button>

            {/* Make_reusable divider  */}
            <span style={{ marginLeft: 10, marginRight: 10 }}>|</span>

            <Button type="link" onClick={handleTicket} allCaps={false}>
              {translate('LOGIN.SUPPORT')}
            </Button>
          </Stack>
        </div>
      </div>
    </div>
  );
};

export default Login;
