import { RichTextEditor } from '@/components/RichTextEditor';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  Divider,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import { useState } from 'react';
import { replaceNewlines } from '../helpers';
import { TPart, TPartWithId } from '../types';

type TPartDetailItem = {
  value: TPart['description'];
  bom: string;
  editable: boolean;
};

type TUpdate = {
  id: number;
  description: string;
};

const PartDetailDescription = ({ value, bom, editable }: TPartDetailItem) => {
  const { makeApiCall } = useApiCall<{
    [key: string]: TPartWithId[];
  }>();

  const [currentDescription, setCurrentDescription] = useState(
    replaceNewlines(value.value, 'br'),
  );
  const [description, setDescription] = useState(
    replaceNewlines(value.value, 'br'),
  );
  const [isEditing, setIsEditing] = useState(false);

  const handleCancel = () => {
    setIsEditing(false);
    setCurrentDescription(description);
  };
  const handleConfirm = () => {
    Update({ id: value.id, description: currentDescription as string });
  };

  const Update = (props: TUpdate) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'PUT',
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/part/${bom}/description`,
          payload: { id: props.id, description: props.description },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        if (result.status && result.status !== 200) {
          alert("ERROR: Description couldn't be updated");
          return;
        }
        setIsEditing(false);
        setDescription(currentDescription);
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  return (
    <>
      {isEditing ? (
        <>
          <RichTextEditor
            content={description as string}
            onUpdate={(value) => setCurrentDescription(value)}
          />
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <div
              style={{
                display: 'inline-block',
              }}
            >
              <Stack direction="row" gap={15}>
                <Button type="primaryOutline" allCaps onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" allCaps onClick={handleConfirm}>
                  Confirm changes
                </Button>
              </Stack>
            </div>
          </div>
        </>
      ) : (
        <>
          {description.toString().length >= 8 ? (
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          ) : (
            editable && (
              <>
                <Typography tag="textlarge">Description</Typography>
                <Divider orientation="horizontal" padding={5} />
              </>
            )
          )}

          {editable && (
            <div style={{ marginTop: 10 }}>
              <Button
                type="primaryOutline"
                size="small"
                allCaps
                onClick={() => setIsEditing(true)}
              >
                {description.toString().length < 8
                  ? 'Add description'
                  : 'Edit description'}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PartDetailDescription;
