import { TPart } from '../types';

type TPartDetailItem = {
  value: TPart['casting_vendors'];
};
const PartDetailCastingVendors = ({ value }: TPartDetailItem) => {
  if (!value) return;
  return (
    <ul>
      {value.map((item: string) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};

export default PartDetailCastingVendors;
