import {
  Footer,
  Header,
  MenuOption,
  Tabs,
  useTranslation,
} from '@data-products-and-ai/react-components';
import { useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { version } from '../../../package.json';
import styles from './Layout.module.scss';
import useGroups from '@/hooks/useGroups';
type NaviKey =
  | 'home'
  | 'parts'
  | 'frames'
  | 'data_approval'
  | 'administration'
  | 'about';

type TNavi = {
  key: NaviKey;
  label: string;
};

const Layout = () => {
  const [shownDropdown, setShownDropdown] = useState<NaviKey | null>(null);
  const translate = useTranslation();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const area = pathSegments[1] ? pathSegments[1] : 'home';
  const groups = useGroups();
  const isAzureAdmin = groups
    ? groups.includes(import.meta.env.VITE_AZURE_ADMIN_GROUP)
    : false;

  const navi: TNavi[] = [
    {
      key: 'home',
      label: 'Home',
    },
    {
      key: 'parts',
      label: 'Parts',
    },
    {
      key: 'frames',
      label: 'Frames',
    },
  ];

  isAzureAdmin && navi.push({ key: 'data_approval', label: 'Data Approval' });
  isAzureAdmin &&
    navi.push({
      key: 'administration',
      label: 'Administration',
    });

  navi.push({
    key: 'about',
    label: 'About',
  });

  let timeoutId: NodeJS.Timeout;

  const navigate = useNavigate();

  const handleChange = (value: TNavi['key'] | '') => {
    if (value === 'home') value = '';

    navigate('/' + value);
  };
  const handleDropdown = (url: string) => {
    navigate(url);
  };

  const handleMouseOver = (area: NaviKey) => {
    clearTimeout(timeoutId);
    setShownDropdown(area);
  };

  const handleMouseOut = () => {
    // Hide the dropdown
    timeoutId = setTimeout(() => {
      setShownDropdown(null);
    }, 1000);
  };

  const handleDropdownMouseOver = () => {
    // Cancel the hiding of the dropdown
    clearTimeout(timeoutId);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          {/*Begin Header */}

          <Header
            hasSignOut
            title="BLISS"
            viewTicketsURL={translate('LOGIN.SEETICKETS_URL')}
            menuSupportURL={translate('LOGIN.SUPPORT_URL')}
          />
          <div style={{ position: 'absolute', top: 0, left: 200 }}>
            <Tabs
              data={navi}
              selectedKey={area as NaviKey}
              hasBorder={false}
              minHeight={70}
              fontSize={16}
              indicatorHeight={2}
              sideMargin={3}
              padding={0}
              onChange={handleChange}
              onMouseOver={(value) => handleMouseOver(value as NaviKey)}
              onMouseOut={handleMouseOut}
              allowClickSelected
            />

            <div
              onFocus={handleDropdownMouseOver}
              onMouseOver={handleDropdownMouseOver}
              onMouseOut={handleMouseOut}
              onBlur={handleMouseOut}
              style={{
                display: shownDropdown === 'parts' ? 'block' : 'none',
                position: 'absolute',
                top: 71,
                left: 116,
                zIndex: 99999,
                backgroundColor: '#FFFFFF',
                borderRight: 'solid 1px #ededed',
                borderLeft: 'solid 1px #ededed',
                borderBottom: 'solid 1px #ededed',
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                boxShadow: '3px 3px 10px rgba(0, 0, 0, 0.15)',
              }}
            >
              <MenuOption
                onClick={() => handleDropdown('/parts')}
                onAfterClick={() => setShownDropdown(null)}
              >
                Search Parts
              </MenuOption>
              <MenuOption
                onClick={() => handleDropdown('/parts/compare')}
                onAfterClick={() => setShownDropdown(null)}
              >
                Compare Parts
              </MenuOption>
              <MenuOption
                hasBottomBorder={false}
                onClick={() => handleDropdown('/parts/part_development')}
                onAfterClick={() => setShownDropdown(null)}
              >
                Part Development
              </MenuOption>
            </div>
          </div>

          {/*End Header */}
        </div>
        <div className={styles.content}>
          <Outlet />
        </div>
        <div className={styles.footer}>
          <Footer
            showRestricted
            appName="BLISS"
            appVersion={version + ' ' + import.meta.env.VITE_ENVIRONMENT}
            links={[
              {
                url: translate('LOGIN.SEETICKETS_URL'),
                title: translate('LOGIN.SEETICKETS'),
                target: '_blank',
              },
              {
                url: translate('LOGIN.SUPPORT_URL'),
                title: translate('LOGIN.SUPPORT'),
                target: '_blank',
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default Layout;
