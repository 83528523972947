import { useState } from 'react';
import useAccessToken from './useAccessToken';
import { useMsal } from '@azure/msal-react';

interface RequestOptions {
  method: string;
  url: string;
  headers?: Record<string, string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
  useAsFetch?: boolean;
  bearer?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useApiCall = <T = any>() => {
  const { getSilentToken } = useAccessToken();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const [data, setData] = useState<T | null>(null);
  const { instance } = useMsal();

  const makeApiCall = async ({
    useAsFetch = false,
    bearer = false,
    ...options
  }: RequestOptions) => {
    setIsLoading(true);
    setError(null);

    try {
      const token = await getSilentToken();

      const response = await fetch(options.url, {
        method: options.method,
        headers: {
          ...options.headers,
          Authorization: `${bearer ? 'Bearer ' : ''}${token}`,
        },
        body: options.payload ? JSON.stringify(options.payload) : undefined,
      });

      if (!response.ok) {
        return response;
        // throw new Error(`API request failed with status ${response.status}`);
      }

      const responseData = await response.json();
      if (useAsFetch) return responseData;
      setData(responseData);
    } catch (error) {
      if (error instanceof Error) {
        if (useAsFetch) throw new Error(error.message);
        const errorString = error.toString();
        if (errorString.includes('AADSTS50058')) {
          console.log('Error: AADSTS50058 detected');
          instance.logoutRedirect();
          // Redirect the user to the login page or perform other error handling as needed
        }
        setError(error);
      } else {
        setError(new Error('An unknown error occurred'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { isLoading, error, data, makeApiCall };
};

export default useApiCall;
