import { Link } from 'react-router-dom';
type TLink = {
  baseUrl: string;
  id: string;
  useIdInUrl?: boolean;
  openInNewWindow?: boolean;
};

export const LinkToPage = ({
  baseUrl,
  id,
  useIdInUrl = true,
  openInNewWindow = false,
}: TLink) => {
  return id ? (
    <Link
      to={baseUrl + (useIdInUrl ? id : '')}
      target={openInNewWindow ? '_blank' : ''}
    >
      {id}
    </Link>
  ) : (
    'N/A'
  );
};

export type TTableSize = 'normal' | 'small' | 'extra-small';
