import classNames from 'classnames';
import { TPart } from '../types';
import { LinkToPage } from './PartDetailTables';
import styles from './PartDetail.module.scss';
type TPartDetailItem = {
  value: TPart['applications'];
};
const PartDetailApplications = ({ value }: TPartDetailItem) => {
  if (!value) return;
  return (
    <ul className={styles['ul-none']}>
      {value.map((item: string) => (
        <li
          key={item}
          className={classNames(styles['li-none'], styles['li-border'])}
        >
          <LinkToPage
            id={item}
            baseUrl={`/frames/${item.substring(0, item.indexOf('('))}/`}
          />
        </li>
      ))}
    </ul>
  );
};

export default PartDetailApplications;
