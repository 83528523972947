import { Spinner } from '@/components/Spinner';
import { searchParametersWithEndpointLabel } from '@/contexts/AreaContext/defaultObjects';
import useApiCall from '@/hooks/useApiCall';
import {
  ButtonIcon,
  Select,
  SelectOption,
  Stack,
  Table,
} from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { useEffect, useState } from 'react';
import { TPartStatus, TPartWithId } from '../../types';
import { TTableSize } from '../PartDetailTables';

type TPartStatusTable = {
  status: TPartStatus;
  size?: TTableSize;
  bom: string;
  editable: boolean;
};
type TEditableCell = {
  type: 'pdp_status' | 'engineering_status';
  status_id: number;
  id: number;
  str: string;
};

const PartStatusTable = ({
  status,
  size = 'normal',
  bom,
  editable = true,
}: TPartStatusTable) => {
  const headers: TableHeaderType[] = [
    {
      align: 'left',
      title: 'PDP Status',
    },
    {
      align: 'center',
      title: 'SAP Status',
    },
    {
      align: 'center',
      title: 'Engineering Status',
    },
  ];

  const EditableCell = (props: TEditableCell) => {
    const [cellState, setCellState] = useState({
      publish: false,
      isEditing: false,
      ...props,
    });

    if (cellState.publish)
      return <Spinner borderSize={2} size={16} color="#CCCCCC" />;

    const Comp = () => {
      const FrameList = () => {
        const { isLoading, data, makeApiCall } = useApiCall<{
          [key: string]: TPartWithId[];
        }>();

        useEffect(() => {
          makeApiCall({
            method: 'GET',
            url:
              import.meta.env.VITE_APP_API_URL +
              '/' +
              searchParametersWithEndpointLabel[props.type].endpoint,
            headers: {
              'Content-Type': 'application/json',
            },
          });
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        type TUpdateCell = {
          id: number;
          pdp_status_id?: number;
          engineering_status_id?: number;
          str: string;
        };

        const UpdateCell = (payload: TUpdateCell) => {
          setCellState((prevState) => {
            return { ...prevState, publish: true };
          });

          const update = async () => {
            try {
              return await makeApiCall({
                method: 'PUT',
                useAsFetch: true,
                url:
                  import.meta.env.VITE_APP_API_URL +
                  `/part/${bom}/${
                    searchParametersWithEndpointLabel[props.type].endpoint
                  }`,
                payload: payload,
                headers: {
                  'Content-Type': 'application/json',
                },
              });
            } catch (error) {
              console.error('Error occurred:', error);

              throw error;
            }
          };

          update()
            .then((result) => {
              if (result.status && result.status !== 200) {
                alert("ERROR: Row couldn't be updated");
                setCellState((prevState) => {
                  return { ...prevState, publish: false };
                });
                return;
              }

              setCellState((prevState) => {
                return {
                  ...prevState,
                  isEditing: false,
                  publish: false,
                  str: payload.str,
                  status_id: payload.pdp_status_id
                    ? payload.pdp_status_id
                    : payload.engineering_status_id
                    ? payload.engineering_status_id
                    : 0,
                };
              });
            })
            .catch(() => {
              setCellState((prevState) => {
                return { ...prevState, publish: false };
              });
              alert('Something went wrong');
            });
        };

        if (isLoading)
          return <Spinner borderSize={2} size={16} color="#CCCCCC" />;

        if (data) {
          return (
            <div style={{ display: 'inline-block' }}>
              <Select
                size="small"
                defaultValue={cellState.status_id.toString()}
                id={cellState.type + cellState.id}
                width={200}
                onChange={(event) =>
                  UpdateCell(
                    props.type === 'pdp_status'
                      ? {
                          id: cellState.id,
                          pdp_status_id: parseInt(event.target.value),
                          str: event.target.options[event.target.selectedIndex]
                            .text,
                        }
                      : {
                          id: cellState.id,
                          engineering_status_id: parseInt(event.target.value),
                          str: event.target.options[event.target.selectedIndex]
                            .text,
                        },
                  )
                }
              >
                {data[
                  searchParametersWithEndpointLabel[props.type].endpoint
                ].map((el: TPartWithId) => {
                  return (
                    <SelectOption
                      key={el.id}
                      label={el.value}
                      value={el.id.toString()}
                    />
                  );
                })}
              </Select>
            </div>
          );
        }

        return '';
      };

      return <FrameList />;
    };
    const EditButton = () => {
      if (cellState.publish) return '';
      return cellState.isEditing ? (
        <ButtonIcon
          hoverType="color"
          icon="IconClose"
          size="extra-small"
          tooltipMessage="Cancel"
          onClick={() =>
            setCellState((prevState) => {
              return { ...prevState, isEditing: false };
            })
          }
        />
      ) : (
        <Stack direction="row">
          <ButtonIcon
            hoverType="color"
            icon="IconEdit"
            size="extra-small"
            tooltipMessage="Edit"
            onClick={() =>
              setCellState((prevState) => {
                return { ...prevState, isEditing: true };
              })
            }
          />
        </Stack>
      );
    };

    return (
      <div>
        {cellState.isEditing ? <Comp /> : cellState.str}
        {editable && (
          <div style={{ display: 'inline-block', marginLeft: 10 }}>
            <EditButton />
          </div>
        )}
      </div>
    );
    //return row.isEditing ? <Comp /> : props.str;
  };

  const rows: TableRowData[] = [
    {
      data: {
        pdp_status: (
          <EditableCell
            type="pdp_status"
            id={status.pdp_status.id}
            status_id={status.pdp_status.pdp_status_id}
            str={status.pdp_status.pdp_status_value}
          />
        ),

        sap_status: status.sap_status,

        engineering_status: (
          <EditableCell
            type="engineering_status"
            id={status.engineering_status.id}
            status_id={status.engineering_status.engineering_status_id}
            str={status.engineering_status.engineering_status_value}
          />
        ),
      },
      id: 'part_status',
    },
  ];

  return (
    <Table
      headerVerticalAlign="bottom"
      headers={headers}
      rows={rows}
      indentation={0}
      typographyTag={
        size === 'normal'
          ? 'textdefault'
          : size === 'extra-small'
          ? 'textextrasmall'
          : 'textsmall'
      }
    />
  );
};

export default PartStatusTable;
