import useApiCall from '@/hooks/useApiCall';
import {
  Divider,
  Tabs,
  Typography,
} from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';

import { TPartSearch, TPersonalFiltersArray } from '../Part/types';

import { Spinner } from '@/components/Spinner';
import { CONSTANTS } from '@/constants/constants';
import {
  AreaProvider,
  TSearchParameters,
} from '@/contexts/AreaContext/AreaContext';
import { initialSearchParameters } from '@/contexts/AreaContext/defaultObjects';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TPartApprovalStatus } from '../Frame/types';
import { PartSearch } from './components/PartSearch';
import { PersonalFilters } from './components/PartSearch/PersonalFilters';
import { SearchResultsTable } from './components/SearchResultsTable';

/* function objectToQueryString(params: { [key: string]: any }): string {  
  const searchParams = new URLSearchParams();  
  
  for (const key in params) {  
      searchParams.append(key, params[key]);  
  }  
  
  return searchParams.toString();  
}   */

export type TSearchType = {
  searchType?: 'parts' | 'data_approval';
};
const Parts = ({ searchType = 'parts' }: TSearchType) => {
  document.title = CONSTANTS.en.TITLE + ' - Parts';
  const { makeApiCall } = useApiCall();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<TPartSearch | null>();
  const [personalFilters, setPersonalFilters] =
    useState<TSearchParameters | null>();
  const [personalFiltersEnabled, setPersonalFiltersEnabled] =
    useState<boolean>(false);
  const [personalFiltersAction, setPersonalFiltersAction] = useState<
    'PUT' | 'POST' | undefined
  >();
  const [personalFiltersId, setPersonalFiltersId] = useState<number>();

  const [searchParams, setSearchParams] = useState<string | null>(null);

  const navigate = useNavigate();
  const { status } = useParams();
  const [selectedOption, setSelectedOption] = useState<TPartApprovalStatus>(
    searchType === 'parts'
      ? 'APPROVED'
      : status
      ? (status.toUpperCase() as TPartApprovalStatus)
      : 'PENDING',
  );

  type TOption = {
    key: TPartApprovalStatus;
    label: string;
  };

  const options: TOption[] = [
    { key: 'PENDING', label: 'Waiting for approval' },
    { key: 'POSTPONED', label: 'Postponed' },
    { key: 'REJECTED', label: 'Rejected' },
  ];

  const handleTabs = (value: TPartApprovalStatus) => {
    let status: string = '/' + value.toLowerCase();

    //if PENDING just navigate to data_quality root
    if (value === 'PENDING') status = '';

    navigate('/data_approval' + status, { replace: true });

    setSelectedOption(value);
    handleSearch(searchParams);
  };

  const handleSearch = (params: string | null) => {
    setError('');
    setResults(null);
    setIsLoading(true);
    setSearchParams(params);
  };

  useEffect(() => {
    if (!status) {
      if (searchType === 'parts') setSelectedOption('APPROVED');
      else setSelectedOption('PENDING');

      setError('');
      setResults(null);
      setIsLoading(true);
    }
  }, [searchType, status]);

  useEffect(() => {
    if (searchParams) {
      let filter = '?status=' + selectedOption;

      const getPersonalFilters = async () => {
        try {
          const PersonalFilters: TPersonalFiltersArray = await makeApiCall({
            useAsFetch: true,
            method: 'GET',
            url: import.meta.env.VITE_APP_API_URL + '/personal_filters',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          const transformedObject: TSearchParameters = {
            ...initialSearchParameters,
          };

          const objectKeys = <Obj extends object>(obj: Obj): (keyof Obj)[] => {
            return Object.keys(obj) as (keyof Obj)[];
          };

          type TNotSap = keyof TSearchParameters;

          const notSapKeys: TNotSap[] = [
            'application',
            'pdp_status',
            'engineering_status',
            'top_coat',
            'earmark',
            'casting_vendor',
            'source',
            'assembly_cluster',
            'life_cluster',
            'design_feature',
          ];

          objectKeys(PersonalFilters.personal_filters.filters).forEach(
            (key) => {
              //do not include not SAP items in data approval search
              /*  if (searchType === 'data_approval' && notSapKeys.includes(key))
                return; */

              const value =
                PersonalFilters.personal_filters.filters[
                  key as keyof typeof PersonalFilters.personal_filters.filters
                ];

              if (Array.isArray(value)) {
                transformedObject[
                  key as keyof typeof PersonalFilters.personal_filters.filters
                ] = value.join(',');
              } else {
                transformedObject[
                  key as keyof typeof PersonalFilters.personal_filters.filters
                ] = value as string;
              }
            },
          );

          const urlEncodedString = objectKeys(
            PersonalFilters.personal_filters.filters,
          )
            .filter(
              (key) => transformedObject[key as keyof TSearchParameters] !== '',
            ) // Filter out empty values
            .map((key) => {
              if (searchType === 'data_approval' && notSapKeys.includes(key))
                return;

              if (
                transformedObject[key as keyof TSearchParameters].includes(',')
              ) {
                const values =
                  transformedObject[key as keyof TSearchParameters].split(',');

                return values
                  .map(
                    (value) =>
                      `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
                  )
                  .join('&');
              } else {
                return `${encodeURIComponent(key)}=${encodeURIComponent(
                  transformedObject[key as keyof TSearchParameters],
                )}`;
              }
            })
            .join('&');

          setPersonalFiltersEnabled(
            PersonalFilters.personal_filters.is_enabled ?? false,
          );
          setPersonalFilters(transformedObject);
          if (
            PersonalFilters.personal_filters.is_enabled &&
            searchParams.length < 2
          ) {
            filter = filter + '&' + urlEncodedString;
            //  navigate('/parts' + filter, { replace: true });
          }

          return PersonalFilters;
        } catch (error) {
          return null;
          // Handle the error if the API call fails
        }
      };

      const getResults = async (str: string) => {
        try {
          const BOM_List = await makeApiCall({
            useAsFetch: true,
            method: 'GET',
            url: import.meta.env.VITE_APP_API_URL + '/parts' + str,
            headers: {
              'Content-Type': 'application/json',
            },
          });
          // Process the BOM_List if the API call is successful
          setResults(BOM_List);
          setIsLoading(false);
        } catch (error) {
          // Handle the error if the API call fails
          if (error instanceof Error) {
            setResults(null);
            setIsLoading(false);
            setError(error.message);
          }
        }
      };
      // if (searchParams.length <= 1) {
      //if not search params go fecth Personal Filters to see if they exist

      getPersonalFilters().then((result) => {
        if (result === null) {
          setPersonalFiltersAction('POST');
        } else {
          setPersonalFiltersAction('PUT');
          setPersonalFiltersId(result.personal_filters.id);
        }

        if (searchParams) filter = filter + '&' + searchParams;

        getResults(filter);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, selectedOption]);

  const memoizedTable = React.useMemo(() => {
    if (!results) return <></>;
    return (
      <>
        <SearchResultsTable
          key="results"
          parts={results.parts}
          status={selectedOption}
        />
      </>
    );
  }, [results, selectedOption]);

  const BomMessage = () => {
    let isBomSearch = false;
    let bomMessage = <div></div>;

    if (searchParams && results) {
      if (searchParams.includes('bom_list=')) isBomSearch = true;

      if (isBomSearch) {
        const dataArray = searchParams
          .split(/bom_list=|%2C/)
          .filter((item) => item !== '')
          .map((item, index, array) => {
            if (index === array.length - 1) {
              return item.split('&')[0];
            } else {
              return item;
            }
          });

        const missingBomKeys = dataArray.filter(
          (item) => !results.parts.some((part) => part.bom === item),
        );

        /*  const indexMap: Record<string, number> = {};
        dataArray.forEach((bom, index) => {
          indexMap[bom] = index;
        }); */

        if (missingBomKeys.length > 0)
          bomMessage = (
            <div style={{ padding: 10, color: 'red' }}>
              <Typography tag="textsmall">
                The following BOM#s were not found:
                {missingBomKeys.map((item, index) => (
                  <div key={index}>{item}</div>
                ))}
              </Typography>
            </div>
          );
      }
    }
    return bomMessage;
  };

  return (
    <div className="contentContainer">
      <div>
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 30,
          }}
        >
          <AreaProvider>
            {searchType === 'data_approval' && (
              <div style={{ marginBottom: 40 }}>
                <Tabs
                  centered={false}
                  data={options}
                  selectedKey={selectedOption}
                  fontSize={14}
                  onChange={handleTabs}
                />
              </div>
            )}
            <PersonalFilters
              searchType={searchType}
              filters={personalFilters}
              personalFiltersEnabled={personalFiltersEnabled}
              setPersonalFiltersEnabled={setPersonalFiltersEnabled}
              personalFiltersId={personalFiltersId}
              setPersonalFiltersId={setPersonalFiltersId}
              action={personalFiltersAction}
            />

            <PartSearch
              searchType={searchType}
              onSearch={handleSearch}
              filters={personalFilters}
              filtersEnabled={personalFiltersEnabled}
            />
          </AreaProvider>
        </div>
        <Divider orientation="horizontal" padding={30} />
      </div>
      {isLoading && (
        <div
          className="contentContainer"
          style={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner borderSize={8} size={64} color="#CCCCCC" />
        </div>
      )}
      {/* FIXME: Remove error message */}
      {error && <div>Error: {error}</div>}

      {results && (
        <>
          <div style={{ paddingLeft: 10 }}>
            <Typography tag="textsmall">
              {results.parts.length.toLocaleString('de-DE')} result
              {results.parts.length > 1 && 's'}
            </Typography>
          </div>
          <BomMessage />
          {memoizedTable}
        </>
      )}
    </div>
  );
};

export default Parts;
