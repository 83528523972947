import { Stack } from '@data-products-and-ai/react-components';
import { TPart, TPartAlternativeSource } from '../types';
import { LinkToPage } from './PartDetailTables';

type TPartDetailItem = {
  value: TPart['alternative_sources'];
};
const PartDetailAlternativeSources = ({ value }: TPartDetailItem) => {
  if (!value) return;
  return (
    <ul>
      {value.map((item: TPartAlternativeSource) => (
        <li key={item.alternative_source}>
          <Stack direction="row" gap={5}>
            <LinkToPage id={item.alternative_source} baseUrl="/parts/detail/" />
            <div>{item.source_type ? `(${item.source_type})` : ''}</div>
          </Stack>
        </li>
      ))}
    </ul>
  );
};

export default PartDetailAlternativeSources;
