/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import useApiCall from '@/hooks/useApiCall';
import { useContext, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styles from './PartPedigree.module.scss';
import classNames from 'classnames';
import { AreaContext } from '@/contexts/AreaContext';
import {
  Stack,
  Icon,
  Typography,
  Divider,
} from '@data-products-and-ai/react-components';
import { BOM } from '@/components/BOM';
import { TPart } from '../Part/types';
import { Spinner } from '@/components/Spinner';

type TPartPedigree = {
  bom: string;
  frame_family: string;
  part_type: string;
  earmarks: string[];
  successors: TPartPedigree[];
  predecessors: TPartPedigree[];
};

type TNodePre = {
  item: InvertedPedigree;
};
type TNode = {
  item: TPartPedigree;
};
type InvertedPedigree = {
  bom: string;
  earmarks: string[];
  children: InvertedPedigree[];
};

/* 
function invertNestedPedigree(data: TPartPedigree[]) {
  const inverted: InvertedPedigree[] = [];
  const stack: { data: TPartPedigree; children: InvertedPedigree[] }[] = [];

  stack.push(...data.map((item) => ({ data: item, children: [] })));

  while (stack.length) {
    const { data, children } = stack.pop()!;

    if (!data.predecessors.length) {
      inverted.push({ bom: data.bom, children: [] });
    } else {
      for (const predecessor of data.predecessors) {
        stack.push({ bom: predecessor.bom, children: [...children, data.bom] });
      }
    }
  }

  return inverted;
} */

function invertNestedPedigree(data: TPartPedigree[]) {
  const inverted: InvertedPedigree[] = [];
  const stack: { data: TPartPedigree; children: InvertedPedigree[] }[] = [];

  stack.push(...data.map((item) => ({ data: item, children: [] })));

  while (stack.length) {
    const { data, children } = stack.pop()!;

    if (!data.predecessors.length) {
      inverted.push({ bom: data.bom, earmarks: data.earmarks, children });
    } else {
      for (const predecessor of data.predecessors) {
        stack.push({
          data: predecessor,
          children: [
            ...children,
            { bom: data.bom, earmarks: data.earmarks, children: [] },
          ],
        });
      }
    }
  }

  return inverted;
}

const PartPedigree = () => {
  const { part_id } = useParams();
  const { isLoading, error, data, makeApiCall } = useApiCall<TPartPedigree>();
  const navigate = useNavigate();

  const handleBOM = (value: TPart) => {
    navigate('/parts/part_development/' + value.title.bom);
  };

  const { area, setArea } = useContext(AreaContext);
  useEffect(() => {
    if (area !== 'parts') setArea('parts');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (part_id) {
      makeApiCall({
        method: 'GET',
        url: import.meta.env.VITE_APP_API_URL + '/pedigree/' + part_id,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part_id]);

  const Node = ({ item }: TNode) => {
    return (
      <>
        <li>
          {/* {item.predecessors.length > 0 && (
            <ul>
              {item.predecessors.map((predecessor, index) => (
                <Node key={index} item={predecessor} />
              ))}
            </ul>
          )}{' '} */}

          <div
            className={classNames(
              styles.item,
              item.bom === part_id && styles.selectedItem,
            )}
            onClick={() => navigate('/parts/detail/' + item.bom)}
          >
            <div>{item.bom}</div>
            <Typography tag="textsmall">
              {item.earmarks.map((el: string, idx: number) => {
                return <div key={idx}>{el}</div>;
              })}
            </Typography>
          </div>
          {item.successors.length > 0 && (
            <ul>
              {item.successors.map((successor, index) => (
                <Node key={index} item={successor} />
              ))}
            </ul>
          )}
        </li>
      </>
    );
  };

  const NodePre = ({ item }: TNodePre) => {
    return (
      <>
        <li>
          {/* {item.predecessors.length > 0 && (
            <ul>
              {item.predecessors.map((predecessor, index) => (
                <Node key={index} item={predecessor} />
              ))}
            </ul>
          )}{' '} */}
          <div
            className={styles.item}
            onClick={() => navigate('/parts/detail/' + item.bom)}
          >
            <div>{item.bom}</div>
            <Typography tag="textsmall">
              {item.earmarks.map((el: string, idx: number) => {
                return <div key={idx}>{el}</div>;
              })}
            </Typography>
          </div>
          {item.children.length > 0 && (
            <ul>
              {item.children.map((child, index) => (
                <NodePre key={index} item={child} />
              ))}
            </ul>
          )}
        </li>
      </>
    );
  };

  if (isLoading) {
    return (
      <div
        className="contentContainer"
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner borderSize={8} size={64} color="#CCCCCC" />
      </div>
    );
  }

  if (error) {
    return <div className="contentContainer">Error: {error.message}</div>;
  }

  if (data || !part_id) {
    let inverted;

    if (data) inverted = invertNestedPedigree(data.predecessors);

    return (
      <div className="contentContainer">
        <div /* className="sticky" */>
          <Stack direction="row" gap={5} verticalAlign="center">
            <Link to="/parts">Parts</Link>
            <Icon icon="IconCaretRight" iconSize={0.7} color="#666666" />

            {part_id ? (
              <>
                <Link to={'/parts/detail/' + part_id}>{part_id}</Link>
                <Icon icon="IconCaretRight" iconSize={0.7} color="#666666" />
              </>
            ) : null}
          </Stack>

          <Stack direction="row" distribute="space-between">
            <Typography tag="h3">
              Part Development - {data?.bom} ({data?.frame_family}
              {' - '}
              {data?.part_type})
            </Typography>
            <BOM cb={handleBOM} />
          </Stack>

          <Divider orientation="horizontal" padding={20} />
        </div>

        {part_id && inverted && data ? (
          <div className={styles.orgChartContainer}>
            <div className={styles.orgChart}>
              {inverted.length > 0 ? (
                <ul>
                  {inverted.map((item) => (
                    <NodePre key={item.bom} item={item} />
                  ))}
                </ul>
              ) : null}
              <ul>
                <Node item={data} />
              </ul>
            </div>
          </div>
        ) : (
          'Please select a BOM'
        )}
      </div>
    );
  }

  return <div className="contentContainer">Not available</div>;
};

export default PartPedigree;
