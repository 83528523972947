import classNames from 'classnames';
import { TPart, TPartWithId } from '../types';
import { LinkToPage } from './PartDetailTables';
import styles from './PartDetail.module.scss';
type TPartDetailItem = {
  value: TPart['successors'];
};
const PartDetailSuccessors = ({ value }: TPartDetailItem) => {
  if (!value) return;
  return (
    <ul className={styles['ul-none']}>
      {value.map((item: TPartWithId) => (
        <li
          key={item.id}
          className={classNames(styles['li-none'], styles['li-border'])}
        >
          <LinkToPage id={item.value} baseUrl="/parts/detail/" />
        </li>
      ))}
    </ul>
  );
};

export default PartDetailSuccessors;
