import styles from './LoginTitle.module.scss';

const LoginTitle = () => {
  return (
    <div
      style={{
        marginTop: 30,
        position: 'relative',
        height: 170,
        fontFamily: 'Siemens',
      }}
    >
      <div
        style={{
          fontSize: 50,
          top: 16,
          letterSpacing: 0.5,
          fontWeight: 'bold',
        }}
        className={styles.letter}
      >
        BLISS
      </div>
      <div
        style={{
          fontSize: 30,
          top: 16,
          letterSpacing: 0.5,
        }}
      >
        <span className={styles.letter}>BL</span>ade{' '}
        <span className={styles.letter}>I</span>nformation{' '}
        <span className={styles.letter}>S</span>ystems &{' '}
        <span className={styles.letter}>S</span>ervice
      </div>
    </div>
  );
};

export default LoginTitle;
