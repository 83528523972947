import { Spinner } from '@/components/Spinner';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  ButtonIcon,
  Divider,
  Input,
  Stack,
  Typography,
} from '@data-products-and-ai/react-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { TPart, TPartReference } from '../types';
import styles from './PartDetail.module.scss';
import { LinkToPage } from './PartDetailTables';

type TEditButton = {
  id: number;
};

type TPartDetailItem = {
  value: TPart['references'];
  bom: string;
  editable: boolean;
};

type TPredecessorList = {
  id: number;
  originalStr: TPartReference;
  str: TPartReference;
  setDynamicValue: React.Dispatch<React.SetStateAction<TPartReference[]>>;
  setCurrentEdits: React.Dispatch<React.SetStateAction<number[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdate: (...args: any[]) => void;
};

const List = (props: TPredecessorList) => {
  const [originalValue, setOriginalValue] = useState(props.originalStr);
  const [publish, setPublish] = useState(false);

  // Keep original value to revert in case of cancel
  useEffect(() => {
    setOriginalValue(props.originalStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (publish) return <Spinner borderSize={2} size={16} color="#CCCCCC" />;

  const Cancel = () => {
    //New row
    if (props.id === 0) {
      props.setDynamicValue((prev) =>
        prev.filter((item) => item.id !== props.id),
      );
    } else {
      //updated row
      props.setDynamicValue((prevState) => {
        return prevState.map((dynItem) => {
          if (dynItem.id === props.id) {
            return originalValue;
          }
          return dynItem;
        });
      });
    }

    props.setCurrentEdits((prevNumbers) =>
      prevNumbers.filter((number) => number !== props.id),
    );
  };

  const Confirm = () => {
    setPublish(true);
    props.onUpdate();
  };
  return (
    <div
      key={'LIST' + props.id}
      style={{
        display: 'grid',
        gridTemplateColumns: '90% 100px',
        gap: 10,
        paddingBottom: 5,
        paddingTop: 5,
      }}
    >
      <div>
        <div>
          <Typography tag="textsmall_strong">Reference</Typography>
          <Input
            onKeyUp={(event) => event.key === 'Enter' && Confirm()}
            id={'Reference' + props.id}
            size="small"
            defaultValue={props.str.reference}
            onChange={(event) => {
              props.setDynamicValue((prevState) => {
                return prevState.map((dynItem) => {
                  if (dynItem.id === props.id) {
                    return { ...dynItem, reference: event.target.value };
                  }
                  return dynItem;
                });
              });
            }}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Typography tag="textsmall_strong">URL</Typography>
          <Input
            onKeyUp={(event) => event.key === 'Enter' && Confirm()}
            id={'URL' + props.id}
            size="small"
            defaultValue={props.str.url}
            onChange={(event) => {
              props.setDynamicValue((prevState) => {
                return prevState.map((dynItem) => {
                  if (dynItem.id === props.id) {
                    return { ...dynItem, url: event.target.value };
                  }
                  return dynItem;
                });
              });
            }}
          />
        </div>
      </div>
      <div style={{ display: 'flex', verticalAlign: 'bottom' }}>
        <div style={{ alignSelf: 'flex-end', padding: 5 }}>
          <Stack direction="row">
            <ButtonIcon
              hoverType="color"
              icon="IconClose"
              size="extra-small"
              tooltipMessage="Cancel"
              onClick={Cancel}
            />

            <ButtonIcon
              hoverType="color"
              icon="IconCheck"
              size="extra-small"
              tooltipMessage="Confirm"
              onClick={Confirm}
            />
          </Stack>
        </div>
      </div>
    </div>
  );
};

const PartDetailReferences = ({
  value,
  bom,
  editable = true,
}: TPartDetailItem) => {
  const [currentEdits, setCurrentEdits] = useState<number[]>([]);
  const [dynamicValue, setDynamicValue] = useState(value);

  const { makeApiCall } = useApiCall<{
    [key: string]: TPartReference[];
  }>();
  if (!dynamicValue) return;

  const Update = (props: TPartReference) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'PUT',
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/part/${bom}/references`,
          payload: {
            id: props.id,
            reference: props.reference,
            url: props.url,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== props.id),
        );

        if (result.status && result.status !== 200) {
          alert("ERROR: Reference couldn't be updated");

          return;
        }
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };
  const Insert = (props: Partial<TPartReference>) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'POST',
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/part/${bom}/references`,
          payload: {
            reference: props.reference,
            url: props.url,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== 0),
        );

        if (result.status && result.status !== 200) {
          alert("ERROR: Reference couldn't be inserted");

          return;
        }

        setDynamicValue((prevState) =>
          prevState.map((item) =>
            item.id === 0
              ? {
                  ...item,
                  id: result.ID,
                  value: props.reference as string,
                }
              : item,
          ),
        );
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const Delete = (props: Partial<TPartReference>) => {
    if (confirm('Are you sure you want to delete this reference?') === true) {
      const ApiCall = async () => {
        try {
          return await makeApiCall({
            method: 'DELETE',
            useAsFetch: true,
            url: import.meta.env.VITE_APP_API_URL + `/part/${bom}/references`,
            payload: { record_id: props.id },
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          console.error('Error occurred:', error);

          throw error;
        }
      };

      ApiCall()
        .then((result) => {
          if (result.status && result.status !== 200) {
            alert("ERROR: Reference couldn't be deleted");
            return;
          }

          setDynamicValue((prevState) =>
            prevState.filter((item) => item.id !== props.id),
          );
        })
        .catch(() => {
          alert('Something went wrong');
        });
    }
  };

  const EditButton = (props: TEditButton) => {
    return (
      <div className={styles.edit}>
        <div style={{ display: 'inline-block', marginLeft: 20 }}>
          <Stack direction="row" gap={10}>
            <Button
              type="link"
              allCaps={false}
              onClick={() =>
                setCurrentEdits((prevNumbers) => [...prevNumbers, props.id])
              }
            >
              Edit
            </Button>

            <Divider orientation="vertical" />
            <Button
              type="link"
              allCaps={false}
              onClick={() => Delete({ id: props.id })}
            >
              Delete
            </Button>
          </Stack>
        </div>
      </div>
    );
  };

  const addNewRow = () => {
    setCurrentEdits((prevNumbers) => [...prevNumbers, 0]);
    setDynamicValue((prev) => [...prev, { id: 0, reference: '', url: '' }]);
  };
  if (!value) return;

  return (
    <>
      <ul className={styles['ul-none']}>
        {dynamicValue.map((item: TPartReference) => (
          <li
            key={item.id}
            className={classNames(styles['li-none'], styles['li-border'])}
          >
            {currentEdits.includes(item.id) ? (
              <List
                key={'Reference_' + item.id}
                id={item.id}
                str={item}
                originalStr={item}
                setDynamicValue={setDynamicValue}
                setCurrentEdits={setCurrentEdits}
                onUpdate={() =>
                  item.id === 0
                    ? Insert({
                        id: item.id,
                        reference: item.reference,
                        url: item.url,
                      })
                    : Update({
                        id: item.id,
                        reference: item.reference,
                        url: item.url,
                      })
                }
              />
            ) : (
              <div className={styles.editableContent}>
                <LinkToPage
                  id={item.reference}
                  baseUrl={item.url}
                  useIdInUrl={false}
                  openInNewWindow={true}
                />
                {editable && <EditButton id={item.id} />}
              </div>
            )}
          </li>
        ))}

        {editable && (
          <li key="MaintenanceAdd" className={styles['li-none']}>
            {!currentEdits.includes(0) && (
              <Button
                type="primaryOutline"
                size="small"
                onClick={() => addNewRow()}
              >
                Add Reference
              </Button>
            )}
          </li>
        )}
      </ul>
    </>
  );
};

export default PartDetailReferences;
/* 



import { Stack } from '@data-products-and-ai/react-components';
import { TPart, TPartReference } from '../types';
import { LinkToPage } from './PartDetailTables';

type TPartDetailItem = {
  value: TPart['references'];
};
const PartDetailReferences = ({ value }: TPartDetailItem) => {
  if (!value) return;
  return (
    <ul>
      {value.map((item: TPartReference) => (
        <li key={item.reference}>
          <Stack direction="row" gap={5}>
            <LinkToPage
              id={item.reference}
              baseUrl={item.url}
              useIdInUrl={false}
              openInNewWindow={true}
            />
          </Stack>
        </li>
      ))}
    </ul>
  );
};

export default PartDetailReferences; */
