import { Spinner } from '@/components/Spinner';
import useApiCall from '@/hooks/useApiCall';
import { TPartApprovalStatus } from '@/pages/Frame/types';
import { Button, Divider, Stack } from '@data-products-and-ai/react-components';
import { ReactNode, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

type TApprove = {
  id: number;
  status: TPartApprovalStatus;
  bom: string;
  view?: 'Table' | 'Detail';
};

interface CustomLinkProps {
  to: string;
  children: ReactNode;
}
const CustomLink = ({ to, children }: CustomLinkProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    // Prevent event bubbling
    event.stopPropagation();
  };

  return (
    <Link to={to} onClick={handleClick}>
      {children}
    </Link>
  );
};

const Approve = ({ id, status, bom, view = 'Table' }: TApprove) => {
  const { isLoading, makeApiCall } = useApiCall<TApprove>();
  const navigate = useNavigate();

  const [currentStatus, setCurrentStatus] = useState(status);
  const [previousStatus, setPreviousStatus] = useState(status);

  /* Uncomment in case approve button and menu return
  
    const [anchorEl, setAnchorEl] = useState<TAnchorEl>(null);
  const htmlElementRef = useRef<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();


    setAnchorEl({
      left: event.clientX,
      top: event.clientY,
    });
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
*/
  const revert = () => {
    Update({ id, status: previousStatus, bom });
  };

  const handleDetailClick = (bom: string) => {
    let st = '?status=';
    if (status === 'REJECTED' || status === 'APPROVED') return;

    st += status;

    navigate('/parts/detail/' + bom + st);
  };

  const Update = (props: TApprove) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'PUT',
          useAsFetch: true,
          url: import.meta.env.VITE_APP_API_URL + `/part/${props.bom}/status`,
          payload: { id: props.id, status: props.status },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    /* handleClose(); */

    update()
      .then((result) => {
        if (result.status && result.status !== 200) {
          alert("ERROR: Status couldn't be updated");
          return;
        }

        if (view === 'Detail') {
          //Component running in detail should redirect after update
          if (props.status === 'APPROVED') {
            navigate('/parts/detail/' + props.bom, { replace: true });
            return;
          } else {
            navigate('/data_approval/' + props.status.toLowerCase());
            return;
          }
        }

        if (currentStatus !== previousStatus) {
          setPreviousStatus(props.status);
          setCurrentStatus(props.status);
          //Undo
        } else {
          //Normal action
          setPreviousStatus(currentStatus);
          setCurrentStatus(props.status);
        }
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const Undo = () => {
    return (
      <>
        <div>{currentStatus}</div>

        <div style={{ display: 'inline-block' }}>
          <Stack direction="row" gap={5}>
            {currentStatus === 'APPROVED' && (
              <>
                <div style={{ fontSize: 12, fontFamily: 'Siemens' }}>
                  <CustomLink to={'/parts/detail/' + bom}>DETAIL</CustomLink>
                </div>
                <Divider orientation="vertical" padding={5} />
              </>
            )}
            <Button
              type="link"
              size="small"
              onClick={(event) => {
                event.stopPropagation();
                revert();
              }}
            >
              Undo
            </Button>
          </Stack>
        </div>
      </>
    );
  };

  if (!status) return;
  if (status === 'REJECTED' || status === 'APPROVED') return;

  const TableView = () => {
    return isLoading ? (
      <Spinner borderSize={2} size={16} color="#CCCCCC" />
    ) : currentStatus === previousStatus ? (
      <div style={{ display: 'inline-block' }}>
        <Stack direction="row" gap={10}>
          {/* <Button
            type="secondary"
            size="small"
            textColor="#28a745"
            icon={<Icon icon="IconCheck" iconSize={0.5} color="#28a745" />}
            onClick={(event) => {
              event.stopPropagation();
              Update({ id, status: 'APPROVED', bom });
            }}
          >
            Approve
          </Button> */}
          <Button
            type="secondary"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              (status === 'PENDING' || status === 'POSTPONED') &&
                handleDetailClick(String(bom));
            }}
          >
            Detail
          </Button>
          {/*  <div ref={htmlElementRef}>
            {status === 'PENDING' ? (
              <>
                <Button
                  type="secondary"
                  size="small"
                  icon={<Icon icon="IconOptionsHorizontal" iconSize={0.5} />}
                  onClick={handleClick}
                />

                <Menu
                  position="absolute"
                  open={open}
                  onClose={handleClose}
                  anchorEl={anchorEl && anchorEl}
                  offset={{ horizontal: -50, vertical: 10 }}
                >
                  
                  <MenuOption
                    hasBottomBorder={false}
                    onClick={() => Update({ id, status: 'POSTPONED', bom })}
                  >
                    <div>Postpone</div>
                  </MenuOption>
                  <MenuOption
                    hasBottomBorder={false}
                    onClick={() => Update({ id, status: 'REJECTED', bom })}
                  >
                    <div style={{ color: '#DC3545' }}>Reject</div>
                  </MenuOption>
                </Menu>
              </>
            ) : (
              <Button
                type="secondary"
                size="small"
                textColor="#DC3545"
                onClick={(event) => {
                  event.stopPropagation();
                  Update({ id, status: 'REJECTED', bom });
                }}
              >
                Reject
              </Button>
            )}
          </div> */}
        </Stack>
      </div>
    ) : (
      <Undo />
    );
  };
  const DetailView = () => {
    return isLoading ? (
      <Spinner borderSize={2} size={16} color="#CCCCCC" />
    ) : currentStatus === previousStatus ? (
      <div style={{ display: 'inline-block' }}>
        <Stack direction="row" gap={10}>
          <Button
            type="primaryOutline"
            onClick={(event) => {
              event.stopPropagation();
              Update({ id, status: 'APPROVED', bom });
            }}
          >
            Approve
          </Button>

          {status !== 'POSTPONED' && (
            <Button
              type="primaryOutline"
              onClick={(event) => {
                event.stopPropagation();
                Update({ id, status: 'POSTPONED', bom });
              }}
            >
              Postpone
            </Button>
          )}

          <Button
            type="primaryOutline"
            onClick={(event) => {
              event.stopPropagation();
              Update({ id, status: 'REJECTED', bom });
            }}
          >
            Reject
          </Button>
        </Stack>
      </div>
    ) : (
      <Undo />
    );
  };

  return view === 'Detail' ? <DetailView /> : <TableView />;
};

export default Approve;
