import { Stack, Typography } from '@data-products-and-ai/react-components';
import { TPart, TPartOwner } from '../types';

type TPartDetailItem = {
  value: TPart['part_owners'];
};
const PartDetailOwner = ({ value }: TPartDetailItem) => {
  if (!value) return;
  return (
    <Stack direction="row" verticalAlign="top" gap={50}>
      {value.map((part_owner: TPartOwner, index: number) => (
        <div key={index}>
          <Typography tag="textsmall_strong">
            {part_owner.ownership.toUpperCase()}
            {part_owner.owners?.map((owner: string, index: number) => (
              <Typography key={index} tag="textdefault">
                {owner}
              </Typography>
            ))}
          </Typography>
        </div>
      ))}
    </Stack>
  );
};

export default PartDetailOwner;
