import { Table } from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TPartSetCount } from '../../types';
import { TTableSize } from '../PartDetailTables';

type TPartSetCountTable = {
  setcount: TPartSetCount[];
  size?: TTableSize;
};
const PartSetCountTable = ({
  setcount,
  size = 'normal',
}: TPartSetCountTable) => {
  const headers: TableHeaderType[] = [
    {
      align: 'left',
      title: 'Path',
    },
    {
      align: 'left',
      title: 'Description',
    },
    {
      align: 'center',
      title: 'Material Master',
    },
    {
      align: 'center',
      title: 'Number of Sets / Parts',
    },
  ];
  const rows: TableRowData[] = setcount.map((item) => {
    return {
      data: {
        path: item.path,
        description: item.description,
        material_master: item.material_master,
        count: item.count,
      },
      id: item.path + item.count,
    };
  });

  return (
    <Table
      headerVerticalAlign="bottom"
      headers={headers}
      rows={rows}
      indentation={0}
      typographyTag={
        size === 'normal'
          ? 'textdefault'
          : size === 'extra-small'
          ? 'textextrasmall'
          : 'textsmall'
      }
    />
  );
};

export default PartSetCountTable;
