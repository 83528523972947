import { AreaContext } from '@/contexts/AreaContext';
import { Input } from '@data-products-and-ai/react-components';
import { useContext } from 'react';
import { handleInputChange } from '../PartSearch/helpers';

const SearchBom = () => {
  const { setSearchParameters } = useContext(AreaContext);

  return (
    <Input
      id="bom_list"
      placeholder="Enter a BOM or list of BOM"
      onChange={(e) => handleInputChange(e, setSearchParameters)}
    />
  );
};

export default SearchBom;
