import { Spinner } from '@/components/Spinner';
import { CONSTANTS } from '@/constants/constants';
import { AreaContext } from '@/contexts/AreaContext';
import useApiCall from '@/hooks/useApiCall';
import useGroups from '@/hooks/useGroups';
import {
  Divider,
  Icon,
  Stack,
  Table,
  Tabs,
  Typography,
} from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import FrameDetailBulletPoint from './components/FrameDetailBulletPoints';
import FrameDetailDescription from './components/FrameDetailDescription';
import { TFrame, TFrameFamily, TFramePart } from './types';
import FrameDetailDesignation from './components/FrameDetailDesignation';

function createMatrix(
  parts: TFramePart[],
): Record<string, Record<string, string[]>> {
  const matrix: Record<string, Record<string, string[]>> = {};

  parts.forEach((part) => {
    const { position, part_type, bom } = part;
    if (!matrix[position]) {
      matrix[position] = {};
    }

    matrix[position][part_type.trim().toUpperCase().replace(/\s/g, '')] = bom;
  });

  return matrix;
}

const headers: TableHeaderType[] = [
  {
    align: 'left',
    title: 'Row',
  },
  {
    align: 'center',
    title: 'Vanes',
  },
  {
    align: 'center',
    title: 'Blades',
  },
  {
    align: 'center',
    title: 'Ring Segments',
  },
  {
    align: 'center',
    title: 'Seal Rings',
  },
  {
    align: 'center',
    title: 'Preswirler',
  },
];

type TFrameParts = {
  parts: TFramePart[];
};

type TBOM = {
  bom: string[];
};

const FrameDetail = () => {
  const groups = useGroups();
  const { frame_family_id, frame_id } = useParams();
  const [forceUpdate, setForceUpdate] = useState(0);
  const { isLoading, error, data, makeApiCall } = useApiCall<TFrameFamily>();
  const [selectedFrame, setSelectedFrame] = useState<string>('');
  const [selectedFrameData, setSelectedFrameData] = useState<TFrame | null>(
    null,
  );
  const navigate = useNavigate();
  const { setArea } = useContext(AreaContext);

  const canEdit = groups
    ? groups.includes(import.meta.env.VITE_AZURE_ADMIN_GROUP)
    : false;

  useEffect(() => {
    setArea('frames');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabs = (value: string) => {
    navigate('/frames/' + frame_family_id + '/' + value, { replace: true });
    setSelectedFrame(value);
  };

  const BOM = ({ bom }: TBOM) => {
    return bom
      ? bom.map((item: string, idx: number) => (
          <>
            <Link key={idx} to={'/parts/detail/' + item}>
              {item}
            </Link>

            {idx < bom.length - 1 && (
              <div
                style={{
                  display: 'inline-block',
                  paddingLeft: 10,
                  paddingRight: 10,
                  opacity: 0.3,
                }}
              >
                |
              </div>
            )}
          </>
        ))
      : '-';
  };

  const FrameParts = (props: TFrameParts) => {
    const matrix: Record<string, Record<string, string[]>> = createMatrix(
      props.parts,
    );

    const rows: TableRowData[] = Object.keys(matrix)?.map((position, index) => {
      /*  return {
        data: {
          ROW: position,
          VANES: <BOM bom={matrix[position].VANE} />,
          BLADES: <BOM bom={matrix[position].BLADE} />,
          RINGSEGMENT: <BOM bom={matrix[position].RINGSEGMENT} />,
          SEALRING: <BOM bom={matrix[position].SEALRING} />,
          PRESWIRLER: <BOM bom={matrix[position].PRESWIRLER} />,
        },
        id: `row_${index + 1}`,
      }; */

      return {
        data: {
          ROW: position,
          VANES: <BOM bom={matrix[position].VANE} />,
          BLADES: <BOM bom={matrix[position].BLADE} />,
          RINGSEGMENT: <BOM bom={matrix[position].RINGSEGMENT} />,
          SEALRING: <BOM bom={matrix[position].SEALRING} />,
          PRESWIRLER: <BOM bom={matrix[position].PRESWIRLER} />,
        },
        id: `row_${index + 1}`,
      };
    });
    return (
      <>
        <div style={{ marginTop: 40, marginBottom: 20 }}>
          <Typography tag="textdefault_strong">
            Parts classified as standard in BLISS:
          </Typography>

          <Typography tag="textsmall">
            <>
              Table below can be used as control mechanism for attribute
              assignments. The displayed BOMs fulfill following criteria:
              <br />
              Engineering Status {'"'}standard{'"'}, SAP Status 40/37/35, Frame
              Version assignment as given in Title.
              <br />
              If you miss BOMs or find unexpected entries, please correct
              assignments in Part Details.
            </>
          </Typography>
        </div>

        <Table
          headers={headers}
          rows={rows}
          indentation={0}
          headerBackgroundColor="#fafafa"
          hoverRow={false}
        />
      </>
    );
  };

  useEffect(() => {
    makeApiCall({
      method: 'GET',
      url: import.meta.env.VITE_APP_API_URL + '/frame/' + frame_family_id,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  useEffect(() => {
    if (data) {
      setSelectedFrame(frame_id ?? data.frames[0].frame);
    }
  }, [data, frame_id]);

  useEffect(() => {
    if (data) {
      let frameToFind = frame_id ?? data.frames[0].frame;

      if (selectedFrameData !== null) {
        frameToFind = selectedFrame;
      }

      const frameData = data.frames.find(
        (item: TFrame) => item.frame === frameToFind,
      );

      if (frameData) setSelectedFrameData(frameData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFrame, data, frame_id]);

  if (isLoading) {
    return (
      <div
        className="contentContainer"
        style={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Spinner borderSize={8} size={64} color="#CCCCCC" />
      </div>
    );
  }

  if (error) {
    return <div className="contentContainer">Error: {error.message}</div>;
  }

  if (data) {
    document.title = CONSTANTS.en.TITLE + ' - ' + data.title;

    const navi = data.frames?.map((item: TFrame) => {
      return {
        key: item.frame,
        label: item.frame,
      };
    });

    return (
      <div className="contentContainer">
        <div /* className="sticky" */>
          <Stack direction="row" gap={5} verticalAlign="center">
            <Link to="/frames">Frames</Link>
            <Icon icon="IconCaretRight" iconSize={0.7} color="#666666" />
          </Stack>

          <Typography tag="h3">{data.title}</Typography>
          <Divider orientation="horizontal" padding={20} />
        </div>
        {/*    <Typography tag="textdefault">
          <div
            dangerouslySetInnerHTML={{
              __html: replaceNewlines(data.description.value, 'br'),
            }}
          />
        </Typography> */}

        <FrameDetailDescription
          value={data.description}
          frame_family={frame_family_id ?? ''}
          editable={canEdit}
        />

        <div style={{ paddingTop: 50 }}>
          <Tabs
            data={navi}
            selectedKey={selectedFrame ? selectedFrame : navi[0].key}
            fontSize={14}
            onChange={handleTabs}
            scrollable
          />
        </div>
        {selectedFrameData !== null && (
          <div style={{ paddingTop: 20 }}>
            <Typography tag="textlarge">Frame Version Details</Typography>
            <Divider orientation="horizontal" padding={5} />
            <FrameDetailBulletPoint
              value={selectedFrameData.bullet_points}
              frame_family={frame_family_id ?? ''}
              frame={frame_id ?? data.frames[0].frame}
              editable={canEdit}
              setForceUpdate={setForceUpdate}
            />

            <div style={{ paddingTop: 20 }} />
            <Typography tag="textlarge">
              Standard Parts of this Frame Version (under construction)
            </Typography>
            <Divider />
            <div style={{ paddingTop: 20 }} />
            {selectedFrameData.designation && (
              <Typography tag="textdefault_strong">
                SPS Table in SAP:
              </Typography>
            )}
            <FrameDetailDesignation
              value={selectedFrameData.designation}
              frame_family={frame_family_id ?? ''}
              frame={frame_id ?? data.frames[0].frame}
              editable={canEdit}
            />

            <FrameParts parts={selectedFrameData.parts} />
          </div>
        )}
      </div>
    );
  }

  return <div className="contentContainer">No data to display</div>;
};

export default FrameDetail;
