type TReplaceType = 'br' | 'ul' | 'ol';

export function replaceNewlines(str: string, replaceType: TReplaceType = 'br') {
  //Return Unordered List

  if (replaceType === 'ul') {
    const lines = str.split('\n');
    const bulletPointString = lines.map((line) => `• ${line}`).join('\n');

    return replaceNewlines(bulletPointString);
    /*   const lines = str.split('\n');
    return (
      <ul>
        {lines.map((line, index) => (
          <li key={index}>{line}</li>
        ))}
      </ul>
    ); */
  }

  //return Ordered List
  if (replaceType === 'ol') {
    const lines = str.split('\n');
    return (
      <ol>
        {lines.map((line, index) => (
          <li key={index}>{line}</li>
        ))}
      </ol>
    );
  }

  //return default break
  str = str.replace('<br/>', '');
  str = str.replace('<br>', '');
  str = str.replace('</br>', '');
  str = str.replace('<br >', '');
  str = str.replace('</br >', '');
  return str.replace(/\n/g, '<br/>');
}
