import { Spinner } from '@/components/Spinner';
import useApiCall from '@/hooks/useApiCall';
import {
  Button,
  ButtonIcon,
  Divider,
  Input,
  Stack,
} from '@data-products-and-ai/react-components';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { TFrame, TFrameWithID } from '../types';
import styles from './../Frame.module.scss';

type TEditButton = {
  id: number;
};

type TUpdate = {
  id?: number;
  bullet_point?: string;
};

type TPartDetailItem = {
  value: TFrame['bullet_points'];
  frame_family: string;
  frame: string;
  editable: boolean;
  setForceUpdate: React.Dispatch<React.SetStateAction<number>>;
};

type TPredecessorList = {
  id: number;
  originalStr: string;
  str: string;
  setDynamicValue: React.Dispatch<React.SetStateAction<TFrameWithID[]>>;
  setCurrentEdits: React.Dispatch<React.SetStateAction<number[]>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onUpdate: (...args: any[]) => void;
};

const List = (props: TPredecessorList) => {
  const [originalValue, setOriginalValue] = useState(props.originalStr);
  const [publish, setPublish] = useState(false);

  // Keep original value to revert in case of cancel
  useEffect(() => {
    setOriginalValue(props.originalStr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (publish) return <Spinner borderSize={2} size={16} color="#CCCCCC" />;

  const Cancel = () => {
    //New row
    if (props.id === 0) {
      props.setDynamicValue((prev) =>
        prev.filter((item) => item.id !== props.id),
      );
    } else {
      //updated row
      props.setDynamicValue((prevState) => {
        return prevState.map((dynItem) => {
          if (dynItem.id === props.id) {
            return { ...dynItem, value: originalValue };
          }
          return dynItem;
        });
      });
    }

    props.setCurrentEdits((prevNumbers) =>
      prevNumbers.filter((number) => number !== props.id),
    );
  };

  const Confirm = () => {
    setPublish(true);
    props.onUpdate();
  };
  return (
    <div
      key={'LIST' + props.id}
      style={{
        display: 'grid',
        gridTemplateColumns: '80% 100px',
        gap: 10,
        paddingBottom: 5,
        paddingTop: 5,
      }}
    >
      <div>
        <Input
          onKeyUp={(event) => event.key === 'Enter' && Confirm()}
          id={'BulletPoint' + props.id}
          size="small"
          defaultValue={props.str}
          onChange={(event) => {
            props.setDynamicValue((prevState) => {
              return prevState.map((dynItem) => {
                if (dynItem.id === props.id) {
                  return { ...dynItem, value: event.target.value };
                }
                return dynItem;
              });
            });
          }}
        />
      </div>
      <div>
        <Stack direction="row">
          <ButtonIcon
            hoverType="color"
            icon="IconClose"
            size="extra-small"
            tooltipMessage="Cancel"
            onClick={Cancel}
          />

          <ButtonIcon
            hoverType="color"
            icon="IconCheck"
            size="extra-small"
            tooltipMessage="Confirm"
            onClick={Confirm}
          />
        </Stack>
      </div>
    </div>
  );
};

const FrameDetailBulletPoint = ({
  value,
  frame_family,
  frame,
  editable = true,
  setForceUpdate,
}: TPartDetailItem) => {
  const [currentEdits, setCurrentEdits] = useState<number[]>([]);
  const [dynamicValue, setDynamicValue] = useState(value);

  const { makeApiCall } = useApiCall<{
    [key: string]: TFrameWithID[];
  }>();

  useEffect(() => {
    setDynamicValue(value);
  }, [value]);

  if (!dynamicValue) return;

  const Update = (props: TUpdate) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'PUT',
          useAsFetch: true,
          url:
            import.meta.env.VITE_APP_API_URL +
            `/frame/${frame_family}/${frame}/bullet_points`,
          payload: {
            id: props.id,
            bullet_point: props.bullet_point,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== props.id),
        );

        setForceUpdate(Date.now());

        if (result.status && result.status !== 200) {
          alert("ERROR: Maintenance Concept couldn't be updated");

          return;
        }
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };
  const Insert = (props: Partial<TUpdate>) => {
    const update = async () => {
      try {
        return await makeApiCall({
          method: 'POST',
          useAsFetch: true,
          url:
            import.meta.env.VITE_APP_API_URL +
            `/frame/${frame_family}/${frame}/bullet_points`,
          payload: {
            bullet_point: props.bullet_point,
          },
          headers: {
            'Content-Type': 'application/json',
          },
        });
      } catch (error) {
        console.error('Error occurred:', error);

        throw error;
      }
    };

    update()
      .then((result) => {
        setCurrentEdits((prevNumbers) =>
          prevNumbers.filter((number) => number !== 0),
        );
        setForceUpdate(Date.now());

        if (result.status && result.status !== 200) {
          alert("ERROR: Maintenance Concept couldn't be inserted");

          return;
        }

        setDynamicValue((prevState) =>
          prevState.map((item) =>
            item.id === 0
              ? {
                  ...item,
                  id: result.ID,
                  value: props.bullet_point as string,
                }
              : item,
          ),
        );
      })
      .catch(() => {
        alert('Something went wrong');
      });
  };

  const Delete = (props: Partial<TUpdate>) => {
    if (
      confirm('Are you sure you want to delete this bullet point?') === true
    ) {
      const ApiCall = async () => {
        try {
          return await makeApiCall({
            method: 'DELETE',
            useAsFetch: true,
            url:
              import.meta.env.VITE_APP_API_URL +
              `/frame/${frame_family}/${frame}/bullet_points`,
            payload: { record_id: props.id },
            headers: {
              'Content-Type': 'application/json',
            },
          });
        } catch (error) {
          console.error('Error occurred:', error);

          throw error;
        }
      };

      ApiCall()
        .then((result) => {
          if (result.status && result.status !== 200) {
            alert("ERROR: Maintenance concept couldn't be deleted");
            return;
          }

          setDynamicValue((prevState) =>
            prevState.filter((item) => item.id !== props.id),
          );
          setForceUpdate(Date.now());
        })
        .catch(() => {
          alert('Something went wrong');
        });
    }
  };

  const EditButton = (props: TEditButton) => {
    return (
      <div className={styles.edit}>
        <div style={{ display: 'inline-block', marginLeft: 20 }}>
          <Stack direction="row" gap={10}>
            <Button
              type="link"
              allCaps={false}
              onClick={() =>
                setCurrentEdits((prevNumbers) => [...prevNumbers, props.id])
              }
            >
              Edit
            </Button>

            <Divider orientation="vertical" />
            <Button
              type="link"
              allCaps={false}
              onClick={() => Delete({ id: props.id })}
            >
              Delete
            </Button>
          </Stack>
        </div>
      </div>
    );
  };

  const addNewRow = () => {
    setCurrentEdits((prevNumbers) => [...prevNumbers, 0]);
    setDynamicValue((prev) => [...prev, { id: 0, value: '' }]);
  };
  if (!value) return;

  return (
    <>
      <ul className={styles['ul-none']}>
        {dynamicValue.map((item: TFrameWithID) => (
          <li
            key={item.id}
            className={classNames(styles['li-none'], styles['li-border'])}
          >
            {currentEdits.includes(item.id) ? (
              <List
                key={'BulletPoint_' + item.id}
                id={item.id}
                str={item.value}
                originalStr={item.value}
                setDynamicValue={setDynamicValue}
                setCurrentEdits={setCurrentEdits}
                onUpdate={() =>
                  item.id === 0
                    ? Insert({ bullet_point: item.value })
                    : Update({ id: item.id, bullet_point: item.value })
                }
              />
            ) : (
              <div className={styles.editableContent}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: item.value,
                  }}
                />

                {editable && <EditButton id={item.id} />}
              </div>
            )}
          </li>
        ))}

        {editable && (
          <li
            key="MaintenanceAdd"
            className={styles['li-none']}
            style={{ marginTop: 20 }}
          >
            {!currentEdits.includes(0) && (
              <Button
                type="primaryOutline"
                size="small"
                onClick={() => addNewRow()}
              >
                Add bullet point
              </Button>
            )}
          </li>
        )}
      </ul>
    </>
  );
};

export default FrameDetailBulletPoint;
