import { Icon } from '@data-products-and-ai/react-components';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import { EditorProvider, useCurrentEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import './RichTextEditor.scss';
const MenuBar = () => {
  const { editor } = useCurrentEditor();

  if (!editor) {
    return null;
  }

  return (
    <div className="buttonGroup">
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5,30px)' }}>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <Icon icon="IconBold" iconSize={0.75} />
        </button>

        <button
          onClick={() => {
            editor.chain().focus().toggleItalic().run();
          }}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <Icon icon="IconItalic" iconSize={0.75} />
        </button>

        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <Icon icon="IconUnorderedList" />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleSuperscript().run()}
          className={editor.isActive('superscript') ? 'is-active' : ''}
        >
          <Icon icon="IconSuperscript" />
        </button>
        <button
          onContextMenu={() => console.log(editor)}
          onClick={() => editor.chain().focus().toggleSubscript().run()}
          className={editor.isActive('subscript') ? 'is-active' : ''}
        >
          <Icon icon="IconSubscript" />
        </button>
      </div>
    </div>
  );
};

const extensions = [
  Superscript.configure(),
  Subscript.configure(),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

type TRichTextEditor = {
  content: string;
  onUpdate: (params: string) => void;
};

const RichTextEditor = (props: TRichTextEditor) => {
  return (
    <EditorProvider
      slotBefore={<MenuBar />}
      extensions={extensions}
      content={props.content}
      onUpdate={({ editor }) => props.onUpdate(editor.getHTML())}
    ></EditorProvider>
  );
};

export default RichTextEditor;
