//type TranslationsType = Record<string, any>;
export type TranslationsType = typeof CONSTANTS;
export type Language = keyof typeof CONSTANTS;
export type ConstantKeys<T extends Language> = keyof (typeof CONSTANTS)[T];
/* export type ConstantValue<
  T extends Language,
  K extends ConstantKeys<T>,
> = (typeof CONSTANTS)[T][K]; */

/* type ConstantValue<
  T extends Language,
  K extends ConstantKeys<T>,
> = K extends keyof (typeof CONSTANTS)[T] ? (typeof CONSTANTS)[T][K] : never;
 */
export const CONSTANTS = {
  en: {
    TITLE: 'BLISS',
    LOGIN: {
      URL: '/',
      BUTTON: 'Login with Azure AD',
      REQUEST_ACCESS: 'Request access',
      SUPPORT_URL:
        'https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/e82a61a6-259f-46c7-8ae8-0664b36888e1?hidenavbar=true&Lookup=43',
      SUPPORT: 'Report issue',
      SEETICKETS: 'View all issues',
      SEETICKETS_URL:
        'https://apps.powerapps.com/play/e/default-254ba93e-1f6f-48f3-90e6-e2766664b477/a/f2049a54-5078-48d3-adec-4408adc7af73?hidenavbar=true&Lookup=43',
      REQUEST_ACCESS_EMAIL_SUBJECT: 'BLISS - Request access',
    },
    HOME: {
      TITLE: 'Home',
      WELCOME_TITLE: 'Welcome to Bliss',
      WELCOME_DESCRIPTION:
        'The BLISS - Turbine Blades and Vanes Information System and Services - is a look-up tool for all design variants of turbine main parts of Siemens Energy LGTs (Large Gas Turbines), such as blades, vanes and ring segments. It provides material specifications, application options and correlations between parts.',
    },
    PARTS: {
      URL: '/parts',
      TITLE: 'Parts',
      SEARCH_TITLE: 'Search Parts',
      COMPARE_TITLE: 'Compare Parts',
      PEDIGREE_TITLE: 'Part Development',
    },
    FRAMES: {
      URL: '/frames',
      TITLE: 'Frames',
    },
    DATA_APPROVAL: {
      URL: '/data_approval',
      TITLE: 'Data Approval',
    },
    ADMINISTRATION: {
      URL: '/administration',
      TITLE: 'Administration',
    },
    ABOUT: {
      URL: '/about',
      TITLE: 'About',
    },
  },

  de: {
    TITLE: 'BLISS',

    LOGIN: {
      BUTTON: 'Login mit Azure AD',
      REQUEST_ACCESS: 'Anfrage Zugang',
      SUPPORT: 'Unterstützung',
      REQUEST_ACCESS_EMAIL_SUBJECT: 'BLISS - Request access',
    },
  },
};
/* 
function isStringKey(key: string | number | symbol): key is string {  
  return typeof key === 'string';  
}  
// Define a function to retrieve a constant value  
function getConstantValue<T extends Language, K extends ConstantKeys<T>>(language: T, key: K): ConstantValue<T, K> {  
  if (isStringKey(key)) {  
    const keys = key.split('.') as (keyof typeof CONSTANTS[T])[];  
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let value: any = CONSTANTS[language];  
    for (const k of keys) {  
      value = value[k];  
    }  
    return value as ConstantValue<T, K>;  
  } else {  
    throw new Error('Invalid key type');  
  }  
}  
  
// Usage example  
const englishTitle: string = getConstantValue('en', 'TITLE'); // Accessing the 'TITLE' constant for the English language  
console.log(englishTitle); // Output: 'BLISS'  
  
const germanButton: string = getConstantValue('de', 'LOGIN.BUTTON') as string; // Accessing the 'BUTTON' constant for the German language  
console.log(germanButton); // Output: 'Login mit Azure AD'   */
