import { Table } from '@data-products-and-ai/react-components';
import {
  TableHeaderType,
  TableRowData,
} from '@data-products-and-ai/react-components/lib/components/DataDisplay/Table/types';
import { TPartMaterial } from '../../types';
import { TTableSize } from '../PartDetailTables';

type TPartMaterialTable = {
  material: TPartMaterial;
  size?: TTableSize;
};

const PartMaterialTable = ({
  material,
  size = 'normal',
}: TPartMaterialTable) => {
  const headers: TableHeaderType[] = [
    {
      align: 'left',
      title: 'Base Material',
    },
    {
      align: 'center',
      title: 'Internal Coating',
    },
    {
      align: 'center',
      title: 'External Coating',
    },
    {
      align: 'center',
      title: 'Thermal Barrier',
    },
  ];
  const rows: TableRowData[] = [
    {
      data: material,
      id: 'material',
    },
  ];

  return (
    <Table
      headerVerticalAlign="bottom"
      headers={headers}
      rows={rows}
      indentation={0}
      typographyTag={
        size === 'normal'
          ? 'textdefault'
          : size === 'extra-small'
          ? 'textextrasmall'
          : 'textsmall'
      }
    />
  );
};

export default PartMaterialTable;
